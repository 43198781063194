import "./App.css"
import React from "react"
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom"
import Login from "./components/Login"
import { history } from "./components/history"
import OurApp from "./OurApp"
import Register from "./components/Register"
import Reset from "./components/Reset"
import ErrorPage from "./components/ErrorPage"

const App = () => {
    return (
        <div className='App'>
            <BrowserRouter history={history}>
                <Routes>
                    <Route exact path='/login' element={<Login />} />
                    <Route path='/board' element={<OurApp />} />
                    <Route exact path='/register' element={<Register />} />
                    <Route exact path='/reset' element={<Reset />} />
                    <Route exact path='/error' element={<ErrorPage />} />
                    <Route
                        exact
                        path='/'
                        element={<Navigate to='/login' />}></Route>
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default App
