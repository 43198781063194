import React, { useEffect, useState } from "react"
import Highcharts, { reduce } from "highcharts/highcharts"
import PieChart from "highcharts-react-official"
import newsLogo from "./static/news_logo_transparent.png"
import stLogo from "./static/stocktwits_logo_orange.png"
import tweetLogo from "./static/twitter_logo_disabled.png"
import chatLogo from "./static/chatroom_icon.jpeg"
import redditLogo from "./static/reddit_logo.png"
import gtrendsLogo from "./static/google_logo2.png"
import TPLogo from "./static/third_party_logo.png"
import { BrowserView, MobileView } from "react-device-detect"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import { styled } from "@mui/material/styles"

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040"
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        fontSize: 15,
        width: 90,
        textAlign: "center"
    }
}))

Highcharts.setOptions({
    colors: Highcharts.map(Highcharts.getOptions().colors, function (color) {
        return {
            radialGradient: {
                cx: 0.9,
                cy: 0.5,
                r: 0.7
            },
            stops: [
                [0, color],
                [1, Highcharts.color(color).brighten(0.4).get("rgb")] // darken
            ]
        }
    })
})

const imageList = {
    News: newsLogo,
    Stocktwits: stLogo,
    "X-Twitter": tweetLogo,
    Chatroom: chatLogo,
    Reddit: redditLogo,
    Trends: gtrendsLogo,
    "Third Party": TPLogo
}

const toolList = {
    News: "news",
    Stocktwits: "stocktwits",
    Trends: "gtrends",
    "Third Party": "thirdParty"
}

const PieCharts = (props) => {
    const availableSources = ["News", "Stocktwits", "Third Party", "Reddit"]

    const setColors = (title) => {
        if (availableSources.includes(title)) {
            return {
                SSell: "#b5352d",
                WSell: "#cf4e47",
                Neutral: "#f8cc47",
                WBuy: "#5f9d5f",
                SBuy: "#3b883f"
            }
        } else {
            return {
                SSell: "#7e7e7e",
                WSell: "#939393",
                Neutral: "#696969",
                WBuy: "#a8a8a8",
                SBuy: "#d3d3d3"
            }
        }
    }
    const sumValues = (obj) => Object.values(obj).reduce((a, b) => a + b)

    const setData = (title, data) => {
        if (!(Object.keys(data).length === 0)) {
            if (title === "Trends") {
                return [
                    {
                        name: "Sell Trend",
                        y: data.Sell ? data.Sell : 50,
                        color: setColors(title).SSell,
                        per: data.Sell / sumValues(data)
                    },
                    {
                        name: "Buy Trend",
                        y: data.Buy ? data.Buy : 50,
                        color: setColors(title).SBuy,
                        per: data.Buy / sumValues(data)
                    }
                ]
            } else if (title === "Third Party") {
                if (sumValues(data) > 0) {
                    return [
                        {
                            name: "Bullish",
                            y: data.Bullish,
                            color: setColors(title).SBuy,
                            per: data.Bullish / sumValues(data)
                        },
                        {
                            name: "Bearish",
                            y: data.Bearish,
                            color: setColors(title).SSell,
                            per: data.Bearish / sumValues(data)
                        }
                    ]
                } else {
                    return [
                        {
                            name: "Bearish",
                            y: 0,
                            color: setColors(title).SSell,
                            per: 1
                        },
                        {
                            name: "Bullish",
                            y: 0,
                            color: setColors(title).SBuy,
                            per: 1
                        }
                    ]
                }
            } else {
                if (sumValues(data) > 0) {
                    return [
                        {
                            name: "Strong Sell",
                            y: data["Strong Sell"],
                            color: setColors(title).SSell,
                            per: data["Strong Sell"] / sumValues(data)
                        },
                        {
                            name: "Weak Sell",
                            y: data["Weak Sell"],
                            color: setColors(title).WSell,
                            per: data["Weak Sell"] / sumValues(data)
                        },
                        {
                            name: "Strong Buy",
                            y: data["Strong Buy"],
                            color: setColors(title).SBuy,
                            per: data["Strong Buy"] / sumValues(data)
                        },
                        {
                            name: "Weak Buy",
                            y: data["Weak Buy"],
                            color: setColors(title).WBuy,
                            per: data["Weak Buy"] / sumValues(data)
                        },
                        {
                            name: "Neutral",
                            y: data["Neutral"],
                            color: setColors(title).Neutral,
                            per: data["Neutral"] / sumValues(data)
                        }
                    ]
                } else {
                    return [
                        {
                            name: "Strong Sell",
                            y: 25,
                            color: setColors(title).SSell,
                            per: 1
                        },
                        {
                            name: "Weak Sell",
                            y: 25,
                            color: setColors(title).WSell,
                            per: 1
                        },
                        {
                            name: "Strong Buy",
                            y: 25,
                            color: setColors(title).SBuy,
                            per: 1
                        },
                        {
                            name: "Weak Buy",
                            y: 25,
                            color: setColors(title).WBuy,
                            per: 1
                        },
                        {
                            name: "Neutral",
                            y: 25,
                            color: setColors(title).Neutral,
                            per: 1
                        }
                    ]
                }
            }
        } else {
            if (title === "Trends") {
                return [
                    {
                        name: "Sell Trend",
                        y: 50,
                        color: setColors(title).SSell,
                        per: 0
                    },
                    {
                        name: "Buy Trend",
                        y: 50,
                        color: setColors(title).SBuy,
                        per: 0
                    }
                ]
            } else if (title === "Third Party") {
                return [
                    {
                        name: "Bearish",
                        y: 5,
                        color: setColors(title).SSell,
                        per: 0
                    },
                    {
                        name: "Bullish",
                        y: 5,
                        color: setColors(title).SBuy,
                        per: 0
                    }
                ]
            } else {
                return [
                    {
                        name: "Strong Sell",
                        y: 25,
                        color: setColors(title).SSell,
                        per: 1
                    },
                    {
                        name: "Weak Sell",
                        y: 25,
                        color: setColors(title).WSell,
                        per: 1
                    },
                    {
                        name: "Strong Buy",
                        y: 25,
                        color: setColors(title).SBuy,
                        per: 1
                    },
                    {
                        name: "Weak Buy",
                        y: 25,
                        color: setColors(title).WBuy,
                        per: 1
                    },
                    {
                        name: "Neutral",
                        y: 25,
                        color: setColors(title).Neutral,
                        per: 1
                    }
                ]
            }
        }
    }

    const [stockOptions, setOptions] = useState({
        chart: {
            renderTo: "container",
            type: "pie",
            margin: 0,
            height: 200,
            // spacing: [0,0,0,0],/
            width: 180,
            render: function () {
                // let xValue = (this.xAxis[0].toPixels(Date.UTC(2014, 10, 20)));
                this.renderer
                    .image(
                        "https://www.highcharts.com/samples/graphics/sun.png",
                        10,
                        10,
                        30,
                        30
                    )
                    .add()
            }
            // events: {
            //   load: function() {
            //     this.renderer.text("<i style='font-size:24px' class='fa'>&#xf2b9;</i>", 355, 80, true).add();
            //       }
            //     }
        },

        title: {
            text: props.title,
            widthAdjust: "-60",
            verticalAlign: "bottom",
            useHtml: false
        },

        plotOptions: {
            pie: {
                shadow: false,
                size: 130,
                innerSize: "70%",
                // height: 180,
                dataLabels: {
                    enabled: false
                }
            }
        },
        tooltip: {
            formatter: function () {
                return "<b>" + this.point.name + "</b> rr " + this.y + " "
            }
        },
        credits: {
            enabled: false
        },

        series: [
            {
                name: "Browsers",
                data: setData(props.type, props.values)
            }
        ]
    })

    useEffect(() => {
        try {
            setOptions((t) => {
                return {
                    chart: {
                        renderTo: "container",
                        type: "pie",
                        margin: 10,
                        height: 240,
                        width: 145,
                        render: function () {
                            // let xValue = (this.xAxis[0].toPixels(Date.UTC(2014, 10, 20)));
                            this.renderer
                                .image(
                                    "https://www.highcharts.com/samples/graphics/sun.png",
                                    10,
                                    10,
                                    30,
                                    30
                                )
                                .add()
                        }
                    },

                    title: {
                        verticalAlign: "bottom",
                        useHtml: true,
                        floating: true,
                        text: [props.type].toString()
                    },

                    plotOptions: {
                        pie: {
                            shadow: false,
                            size: "125",
                            innerSize: "73%",
                            dataLabels: {
                                enabled: false
                            }
                        }
                    },
                    tooltip: {
                        formatter: function () {
                            return (
                                "<b>" +
                                this.point.name +
                                "</b><br>" +
                                this.y.toFixed(2) +
                                "<br>" +
                                (this.point.per * 100).toFixed(2) +
                                "%"
                            )
                        },
                        outside: true,
                        style: {
                            zIndex: 6
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [
                        {
                            name: "Browsers",
                            data: setData(props.type, props.values)
                        }
                    ]
                }
            })
        } catch (error) {
            // Handle the error
            console.error("An error occurred:", error)
            alert("Pie charts error")
            // Set an error state if needed
            // setError(true);
        }
    }, [props.values])

    return (
        <div style={{ maxHeight: "80% !important" }}>
            <div style={{ zIndex: 4, paddingRight: 12 }} className='container'>
                <BrowserView>
                    <PieChart highcharts={Highcharts} options={stockOptions} />
                    <img
                        src={imageList[props.type]}
                        height={40}
                        width={48}
                        style={{
                            zIndex: 4,
                            position: "relative",
                            marginTop: "-110%",
                            marginBottom: "75%",
                            xRef: "paper",
                            yRef: "paper",
                            xAnchor: "center",
                            yAnchor: "middle",
                            filter: availableSources.includes(props.type)
                                ? "none"
                                : "grayscale(100%)"
                        }}></img>
                </BrowserView>
                <MobileView>
                    <PieChart highcharts={Highcharts} options={stockOptions} />
                    <img
                        src={imageList[props.type]}
                        height={40}
                        width={48}
                        style={{
                            zIndex: 4,
                            position: "relative",
                            marginTop: "-50%",
                            marginBottom: "28%",
                            xRef: "paper",
                            yRef: "paper",
                            xAnchor: "center",
                            yAnchor: "middle",
                            filter: availableSources.includes(props.type)
                                ? "none"
                                : "grayscale(100%)"
                        }}></img>
                </MobileView>
            </div>
        </div>
    )
}

export default PieCharts
