import "bootstrap/dist/css/bootstrap.min.css"
import React, { useState, useEffect } from "react"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import { Button, Modal, Backdrop, IconButton } from "@material-ui/core"
import {
    Card,
    Slide,
    CardContent,
    CardHeader,
    Typography,
    CardMedia,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Switch,
    ButtonGroup,
    Stack,
    Chip,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material"
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import fullScreen from "highcharts/modules/full-screen"
import wordCloud from "highcharts/modules/wordcloud.js"
import "highcharts/css/annotations/popup.css"
import Highcharts, { useSerialIds } from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"
import api from "./api_ui"
import Moment from "moment-timezone"
import Wordcloud from "./Wordcloud"
import CloseIcon from "@mui/icons-material/Close"
import indicatorsAll from "highcharts/indicators/indicators-all"
import annotationsAdvanced from "highcharts/modules/annotations-advanced"
import priceIndicator from "highcharts/modules/price-indicator"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone.js"
import utc from "dayjs/plugin/utc"
import * as customParseFormat from "dayjs/plugin/customParseFormat"
import { getGraphData } from "../utils/functions"
import { SINGLE_GRAPH_DURATION } from "../utils/constants"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import DashboardAlert from "./page/DashboardAlert"
import dateJson from "./static/data/date.json"

wordCloud(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
indicatorsAll(Highcharts)
annotationsAdvanced(Highcharts)
priceIndicator(Highcharts)
fullScreen(Highcharts)
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
const GRAPH_NORMALIZE_FACTOR = 20

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white !important"
    },
    icon: {
        fill: "blue"
    },
    modal: {
        margin: "auto",
        marginTop: "5%",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 3, 2),
        paddingRight: 0
    },
    fullscreenBackground: {
        border: "solid 2px red !important",
        height: "100% !important"
    },
    summaryRoot: {
        justifyContent: "flex-start !important"
    },
    summaryContent: {
        display: "flex",
        alignItems: "center",
        flexGrow: "unset !important",
        marginLeft: "10px"
    }
}))

let initialStockOptions = {
    buzz: {
        chart: {
            type: "bar"
        },
        plotOptions: {
            series: {
                stacking: "normal"
            },
            visible: false
        },
        credits: {
            text: "finsoftai.com",
            href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
            style: {
                fontSize: 14,
                textDecoration: "underline",
                color: "#0248b3"
            },
            position: {
                align: "right",
                x: -10
            }
        },
        stockTools: {
            gui: {
                enabled: false // disable the built-in toolbar
            }
        },
        xAxis: [
            {
                title: {
                    text: "Tickers",
                    style: {
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "Poppins"
                    }
                }
            }
        ],

        yAxis: [
            {
                title: {
                    text: "Buzz Volume",
                    style: {
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "Poppins"
                    }
                }
            }
        ],
        title: {
            text: ""
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen"]
                }
            }
        },
        series: [
            {
                data: [{ x: 1, y: 13 }]
            }
        ]
    },
    sentiment: {
        chart: {
            type: "bar",
            styledmode: "on"
        },
        credits: {
            text: "finsoftai.com",
            href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
            style: {
                fontSize: 14,
                textDecoration: "underline",
                color: "#0248b3"
            },
            position: {
                align: "right",
                x: -10
            }
        },
        stockTools: {
            gui: {
                enabled: false // disable the built-in toolbar
            }
        },
        xAxis: [
            {
                title: {
                    text: "Tickers",
                    style: {
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "Poppins"
                    }
                }
            }
        ],

        yAxis: [
            {
                title: {
                    text: "sentiment",
                    style: {
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "Poppins"
                    }
                }
            }
        ],
        title: {
            text: ""
        },

        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen"]
                }
            }
        },

        labels: {
            formatter: function () {
                return Highcharts.numberFormat(this.value, 2)
            }
        },
        series: [
            {
                name: "Tickers",
                data: [
                    0.481, 0.262, 0.253, 0.215, 0.131, 0.112, 0.94, -0.35, -0.3,
                    -0.8
                ]
            }
        ]
    },
    change: {
        chart: {
            type: "bar",
            styledmode: "on"
        },
        credits: {
            text: "finsoftai.com",
            href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
            style: {
                fontSize: 14,
                textDecoration: "underline",
                color: "#0248b3"
            },
            position: {
                align: "right",
                x: -10
            }
        },
        stockTools: {
            gui: {
                enabled: false // disable the built-in toolbar
            }
        },
        xAxis: [
            {
                title: {
                    text: "Tickers",
                    style: {
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "Poppins"
                    }
                }
            }
        ],

        yAxis: [
            {
                title: {
                    text: "sentiment",
                    style: {
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "Poppins"
                    }
                }
            }
        ],
        title: {
            text: ""
        },

        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen"]
                }
            }
        },

        labels: {
            formatter: function () {
                return Highcharts.numberFormat(this.value, 2)
            }
        },
        series: [
            {
                name: "Tickers",
                data: [
                    0.481, 0.262, 0.253, 0.215, 0.131, 0.112, 0.94, -0.35, -0.3,
                    -0.8
                ],
                color: "#43a84a",
                negativeColor: "#e64736",

                type: "bar",
                pointStart: 0
            }
        ]
    }
}

const sortSeries = (data1, data2, key) => {
    let multiplier =
        (key === "sentiment") | (key === "sentimentChange") ? 0.5 : 1

    const dataList = Object.keys(data1).map((key) => {
        return {
            value: multiplier * data1[key] + multiplier * data2[key],
            name: key,
            d1: data1[key],
            d2: data2[key]
        }
    })

    dataList.sort((a, b) => (a.value < b.value ? 1 : -1)).splice(30, 1)

    if (key === "buzzChange") {
        let tickerList = dataList.map((t) => t.name)
        let topTickerList = tickerList
            .slice(0, 5)
            .concat(tickerList.slice(25, 30))
        tickerList = tickerList.filter((item) => {
            return item !== "fields"
        })
        topTickerList = topTickerList.filter((item) => {
            return item !== "fields"
        })

        return { all: tickerList, top: topTickerList }
    } else if (
        (key === "buzz") |
        (key === "sentiment") |
        (key === "sentimentChange")
    ) {
        //sortSentimentSeries
        let tickerList = dataList.map((t) => t.name)
        let topTickerList = tickerList
            .slice(0, 5)
            .concat(tickerList.slice(25, 30))
        tickerList = tickerList.filter((item) => {
            return item !== "fields"
        })
        topTickerList = topTickerList.filter((item) => {
            return item !== "fields"
        })

        return { all: tickerList, top: topTickerList }
    }
}

const getSeries = (
    data1,
    data2,
    data3,
    data4,
    source,
    dataToggle,
    tickerList
) => {
    let toggle = dataToggle ? "all" : "top"
    try {
        let temp = tickerList[toggle].map((key) => {
            return {
                y: parseFloat((0.5 * data1[key] + 0.5 * data2[key]) / 1),
                stPercent: data2[key],
                newsPercent: data1[key],
                newsAbs: data3[key],
                stAbs: data4[key]
            }
        })
        return {
            name: source,
            type: "bar",
            data: dataToggle ? temp : temp.slice(0, 11),
            pointStart: 0,
            color: "#43a84a",
            negativeColor: "#e64736"
        }
    } catch (r) {
        let temp = tickerList[toggle].map((key) => {
            return {
                y: parseFloat((0.5 * data1[key] + 0.5 * data2[key]) / 1),
                st: data2[key],
                news: data1[key]
            }
        })
        return {
            name: source,
            type: "bar",
            data: dataToggle ? temp : temp.slice(0, 11),
            pointStart: 0,
            color: "#43a84a",
            negativeColor: "#e64736"
        }
    }
}

function getSeriesTwo(data1, data2, source, dataToggle, tickerList) {
    let toggle = dataToggle ? "all" : "top"

    let temp = tickerList[toggle].map((key) => {
        return {
            y: parseFloat(data1[key] + data2[key] * GRAPH_NORMALIZE_FACTOR),
            st: data2[key] * GRAPH_NORMALIZE_FACTOR,
            news: data1[key]
        }
    })
    return {
        name: source,
        type: "bar",
        data: dataToggle ? temp : temp.slice(0, 11),
        pointStart: 0,
        color: "#43a84a",
        negativeColor: "#e64736"
    }
}

const getSeriesChangeTwo = (data, source, dataToggle, tickerList) => {
    let temp = tickerList.map((key) => {
        return data[key]
    })
    return {
        name: source,
        data: dataToggle ? temp : temp.slice(0, 11)
    }
}

const findSeries = (Sname, data, changeFlag) => {
    let data1 = null
    let data2 = null
    let data3 = null
    let data4 = null
    if (changeFlag) {
        data1 = data.find((o) => o.fields === Sname + "_st_change_percent")
        data2 = data.find((o) => o.fields === Sname + "_news_change_percent")
        data3 = data.find((o) => o.fields === Sname + "_st_change")
        data4 = data.find((o) => o.fields === Sname + "_news_change")
        return { news: data2, st: data1, newsAbs: data4, stAbs: data3 }
    } else {
        data1 = data.find((o) => o.fields === Sname + "_st")
        data2 = data.find((o) => o.fields === Sname + "_news")

        return { news: data2, st: data1 }
    }
}

const TrendingGraphs = (props) => {
    const classes = useStyles()
    const [graphModalToggle, setGraphToggle] = useState(true)
    const [graphViews, setGraphViews] = useState({
        buzz: "one_day",
        sentiment: "one_day",
        changeBuzz: "one_day",
        changeSentiment: "one_day"
    })
    const [graphDataCount, setGraphDataCount] = useState({
        buzz: false,
        sentiment: false,
        changeBuzz: false,
        changeSentiment: false
    })
    const [buzzGraph, setBuzzGraph] = useState(initialStockOptions["buzz"])
    const [buzzGraphChange, setBuzzGraphChange] = useState(
        initialStockOptions["sentiment"]
    )
    const [sentiGraph, setSentiGraph] = useState(
        initialStockOptions["sentiment"]
    )
    const [sentiGraphChange, setSentiGraphChange] = useState(
        initialStockOptions["change"]
    )
    const [datesList, setDatesList] = useState([])
    const [displayDates, setDisplayDates] = useState({
        buzz: "",
        sentiment: "",
        changeBuzz: "",
        changeSentiment: ""
    })
    const [dateChangeModal, setDateChangeModal] = useState(false)
    const [graphDateTime, setGraphDateTime] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const screen1 = useFullScreenHandle()
    const screen2 = useFullScreenHandle()
    const screen3 = useFullScreenHandle()
    const screen4 = useFullScreenHandle()
    const [btnGroups, setbtnGroups] = useState([1, 1, 1, 1])
    const [updatedDate, setUpdatedDate] = useState(null)

    useEffect(() => {
        getGraphs()
    }, [graphViews, graphDataCount])

    useEffect(() => {
        if (props.modalToggle) {
            setGraphToggle(() => {
                return true
            })
        }
    }, [props.modalToggle])

    useEffect(() => {
        props.setModalToggle(graphModalToggle)
    }, [graphModalToggle])

    useEffect(() => {
        setViews(1, "one_day")
        setViews(2, "one_day")
        setViews(3, "one_day")
        setViews(4, "one_day")
        let newDate = new Date()
        setUpdatedDate(() => {
            return Moment(newDate).format("DD/MMMM/YYYY")
        })
    }, [datesList])

    const mapStockDataByDuration = (durationData, stock) => {
        if (stock !== "FB/META") return durationData[stock]
        else return durationData["FB"] ?? durationData["META"]
    }

    const getSingleAbsoluteGraphData = (data, stock) => {
        const labels = [
            "one_day_st",
            "one_week_st",
            "two_week_st",
            "one_month_st",
            "one_day_news",
            "one_week_news",
            "two_week_news",
            "one_month_news"
        ]

        const dataByDuration = data.filter((durationData) =>
            labels.includes(durationData.fields)
        )

        if (stock) {
            const stockDataByDuration = dataByDuration.map((durationData) => {
                return mapStockDataByDuration(durationData, stock)
            })

            return stockDataByDuration
        }

        return dataByDuration
    }

    const getSingleBuzzGraphData = (arr) => {
        // Check if the array has an odd length
        if (arr.length % 2 !== 0) {
            return "Array length must be even."
        }

        // Find the middle index
        const middleIndex = arr.length / 2

        // Divide the array into two halves
        const firstHalf = arr.slice(0, middleIndex)
        const secondHalf = arr.slice(middleIndex)

        const result = {
            name: "Score",
            type: "bar",
            pointStart: 0,
            data: firstHalf.map((prevIndex, currIndex) => {
                const st = firstHalf[currIndex] * GRAPH_NORMALIZE_FACTOR
                const news = secondHalf[currIndex]
                const y = st + news

                return {
                    y,
                    st,
                    news
                }
            }),
            color: "#43a84a",
            negativeColor: "#e64736"
        }

        return result
    }

    const getSingleChangeGraphData = (data, stock) => {
        const labels = [
            "one_day_st_change_percent",
            "one_week_st_change_percent",
            "two_week_st_change_percent",
            "one_day_news_change_percent",
            "one_month_st_change_percent",
            "one_week_news_change_percent",
            "two_week_news_change_percent",
            "one_month_news_change_percent"
        ]

        const dataByDuration = data.filter((durationData) =>
            labels.includes(durationData.fields)
        )

        if (stock) {
            const stockDataByDuration = dataByDuration.map((durationData) => {
                return mapStockDataByDuration(durationData, stock)
            })

            return stockDataByDuration
        }

        return dataByDuration
    }

    // Draw Graphs
    // 1.
    const drawBuzzGraph = (arrB, tickerList, response) => {
        setBuzzGraph(() => {
            return {
                title: {
                    text: "Trending stocks by Social Buzz"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5
                },
                lang: {
                    thousandsSep: ","
                },
                colors: ["#43a84a", "#f5d561"],
                legend: {
                    enabled: false,
                    title: {
                        text: "",
                        style: {
                            fontStyle: "italic"
                        }
                    }
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                getSingleBuzzGraphView(
                                    event.point,
                                    tickerList,
                                    arrB,
                                    response
                                )
                            }
                        }
                    }
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: "Tickers",
                            style: {
                                fontWeight: "bold",
                                fontSize: "17px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: tickerList,
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        title: {
                            text: "Average Social Buzz",
                            style: {
                                fontWeight: "bold",
                                fontSize: "17px",
                                fontFamily: "Poppins"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Buzz volume: {point.y:,.1f}</b><p>Social Media:{point.st:,.2f} | News:{point.news:,.2f} </p></div>'
                },
                series: arrB
            }
        })
    }

    // 2.
    const drawSentimentGraph = (arrB, tickerList, toggle, response) => {
        setSentiGraph(() => {
            return {
                title: {
                    text: "Trending stocks by Sentiment"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5
                },
                colors: ["#43a84a", "#f5d561"],
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                getSingleSentimentGraphView(
                                    event.point,
                                    tickerList,
                                    arrB,
                                    toggle,
                                    response
                                )
                            }
                        }
                    }
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: "Tickers",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: tickerList,
                        plotLines: [
                            {
                                value: graphDataCount.sentiment ? null : 4.5,
                                width: 1,
                                color: "#aaa",
                                zIndex: 10
                            }
                        ],
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                // legend:{enabled:false},
                yAxis: [
                    {
                        title: {
                            text: "Average Social Sentiment Score",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Social Sentiment: {point.y:,.3f}</b><p>Social Media:{point.st:,.3f} | News:{point.news:,.3f} </p></div>'
                    // valueDecimal:1,
                    // valueDecimal:1,
                },
                series: arrB
            }
        })
    }

    // 3.
    const drawBuzzChangeGraph = (arrBTWO, tickerList, toggle, response) => {
        setBuzzGraphChange(() => {
            return {
                title: {
                    text: "Trending stocks by Change in Social Buzz"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    styledmode: "on"
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                getSingleBuzzChangeGraphView(
                                    event.point,
                                    tickerList,
                                    arrBTWO,
                                    toggle,
                                    response
                                )
                            }
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                colors: ["#43a84a", "#f5d561"],
                lang: {
                    thousandsSep: ","
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: "Tickers",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: tickerList[toggle],
                        plotLines: [
                            {
                                value: graphDataCount.changeBuzz ? null : 4.5,
                                width: 1,
                                color: "#aaa",
                                zIndex: 10
                            }
                        ],
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                // legend:{enabled:false},
                yAxis: [
                    {
                        title: {
                            text: "Average Change in Social Buzz",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        labels: {
                            formatter: function () {
                                return this.value + "%"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },

                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Change in Buzz volume : {point.y:,.2f}%</b><p>Social Media : {point.stPercent:,.2f}% | News : {point.newsPercent:,.2f}%</p></div>', // <br></br>Social Media value: {point.stAbs:,.2f} | News value: {point.newsAbs:,.2f}
                    // valueDecimal:1,
                    padding: 4
                },
                series: arrBTWO
            }
        })
    }

    // 4.
    const drawSentimentChangeGraph = (arrB, tickerList, toggle, response) => {
        setSentiGraphChange(() => {
            return {
                title: {
                    text: "Trending stocks by Change in Sentiment"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    styledmode: "on"
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                getSingleSentimentChangeGraphView(
                                    event.point,
                                    tickerList,
                                    arrB,
                                    toggle,
                                    response
                                )
                            }
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                lang: {
                    thousandsSep: ","
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: "Tickers",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: tickerList[toggle],
                        plotLines: [
                            {
                                value: graphDataCount.changeSentiment
                                    ? null
                                    : 4.5,
                                width: 1,
                                color: "#aaa",
                                zIndex: 10
                            }
                        ],
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                // legend:{enabled:false},
                yAxis: [
                    {
                        title: {
                            text: "Average Change in Social Sentiment Score",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        labels: {
                            formatter: function () {
                                return this.value + "%"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"],
                            className: "step-four"
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Change in Social Sentiment : {point.y:,.2f}%</b><p>Social Media : {point.stPercent:,.2f}% | News : {point.newsPercent:,.2f}%</p></div>' //<br></br>Social Media value: {point.stAbs:,.3f} | News value: {point.newsAbs:,.3f}
                    // valueDecimal:1,
                    // valueDecimal:1,
                },
                series: arrB
            }
        })
    }

    // Get Single Buzz Graph View
    // 1.
    const getSingleBuzzGraphView = (
        { category },
        tickerList,
        arrB,
        response
    ) => {
        const stockDataByDuration = getSingleAbsoluteGraphData(
            response,
            category
        )

        const singleGraphData = getSingleBuzzGraphData(stockDataByDuration)

        setBuzzGraph(() => {
            return {
                title: {
                    text: "Trending stocks by Social Buzz"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5
                },
                lang: {
                    thousandsSep: ","
                },
                colors: ["#43a84a", "#f5d561"],
                legend: {
                    enabled: false,
                    title: {
                        text: "",
                        style: {
                            fontStyle: "italic"
                        }
                    }
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function () {
                                drawBuzzGraph(arrB, tickerList, response)
                            }
                        }
                    }
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: category,
                            style: {
                                fontWeight: "bold",
                                fontSize: "17px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: SINGLE_GRAPH_DURATION,
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],

                yAxis: [
                    {
                        title: {
                            text: "Average Social Buzz",
                            style: {
                                fontWeight: "bold",
                                fontSize: "17px",
                                fontFamily: "Poppins"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Buzz volume: {point.y:,.1f}</b><p>Social Media:{point.st:,.2f} | News:{point.news:,.2f} </p></div>'
                },

                series: singleGraphData
            }
        })
    }

    // 2.
    const getSingleSentimentGraphView = (
        { category },
        tickerList,
        arrB,
        toggle,
        response
    ) => {
        const stockDataByDuration = getSingleAbsoluteGraphData(
            response,
            category
        )

        const singleGraphData = {
            name: "Score",
            type: "bar",
            pointStart: 0,
            data: stockDataByDuration
                .slice(0, stockDataByDuration.length / 2)
                .map((prevIndex, currIndex) => ({
                    y:
                        (prevIndex +
                            stockDataByDuration.slice(
                                stockDataByDuration.length / 2
                            )[currIndex]) /
                        2,
                    st: prevIndex,
                    news: stockDataByDuration.slice(
                        stockDataByDuration.length / 2
                    )[currIndex]
                })),
            color: "#43a84a",
            negativeColor: "#e64736"
        }

        setSentiGraph(() => {
            return {
                title: {
                    text: "Trending stocks by Sentiment"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                drawSentimentGraph(
                                    arrB,
                                    tickerList,
                                    toggle,
                                    response
                                )
                            }
                        }
                    }
                    // visible: false,
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: category,
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: SINGLE_GRAPH_DURATION,
                        plotLines: [
                            {
                                value: graphDataCount.sentiment ? null : 4.5,
                                width: 1,
                                color: "#aaa",
                                zIndex: 10
                            }
                        ],
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                // legend:{enabled:false},
                yAxis: [
                    {
                        title: {
                            text: "Average Social Sentiment Score",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Social Sentiment: {point.y:,.3f}</b><p>Social Media:{point.st:,.3f} | News:{point.news:,.3f} </p></div>'
                    // valueDecimal:1,
                    // valueDecimal:1,
                },
                series: singleGraphData
            }
        })
    }

    // 3.
    const getSingleBuzzChangeGraphView = (
        { category },
        tickerList,
        arrB,
        toggle,
        response
    ) => {
        const stockDataByDuration = getSingleChangeGraphData(response, category)

        const singleGraphData = {
            name: "Score",
            type: "bar",
            pointStart: 0,
            data: stockDataByDuration
                .slice(0, stockDataByDuration.length / 2)
                .map((prevIndex, currIndex) => ({
                    y:
                        (prevIndex +
                            stockDataByDuration.slice(
                                stockDataByDuration.length / 2
                            )[currIndex]) /
                        2,
                    stPercent: prevIndex,
                    newsPercent: stockDataByDuration.slice(
                        stockDataByDuration.length / 2
                    )[currIndex]
                })),
            color: "#43a84a",
            negativeColor: "#e64736"
        }

        setBuzzGraphChange(() => {
            return {
                title: {
                    text: "Trending stocks by Change in Social Buzz"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    styledmode: "on"
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                drawBuzzChangeGraph(
                                    arrB,
                                    tickerList,
                                    toggle,
                                    response
                                )
                            }
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                colors: ["#43a84a", "#f5d561"],
                lang: {
                    thousandsSep: ","
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: category,
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: SINGLE_GRAPH_DURATION,
                        plotLines: [
                            {
                                value: graphDataCount.changeBuzz ? null : 4.5,
                                width: 1,
                                color: "#aaa",
                                zIndex: 10
                            }
                        ],
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                // legend:{enabled:false},
                yAxis: [
                    {
                        title: {
                            text: "Average Change in Social Buzz",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        labels: {
                            formatter: function () {
                                return this.value + "%"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },

                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Change in Buzz volume : {point.y:,.2f}%</b><p>Social Media : {point.stPercent:,.2f}% | News : {point.newsPercent:,.2f}%</p></div>', // <br></br>Social Media value: {point.stAbs:,.2f} | News value: {point.newsAbs:,.2f}
                    // valueDecimal:1,
                    padding: 4
                },
                series: singleGraphData
            }
        })
    }

    // 4.
    const getSingleSentimentChangeGraphView = (
        { category },
        tickerList,
        arrB,
        toggle,
        response
    ) => {
        const stockDataByDuration = getSingleChangeGraphData(response, category)

        const singleGraphData = {
            name: "Score",
            type: "bar",
            pointStart: 0,
            data: stockDataByDuration
                .slice(0, stockDataByDuration.length / 2)
                .map((prevIndex, currIndex) => ({
                    y:
                        (prevIndex +
                            stockDataByDuration.slice(
                                stockDataByDuration.length / 2
                            )[currIndex]) /
                        2,
                    stPercent: prevIndex,
                    newsPercent: stockDataByDuration.slice(
                        stockDataByDuration.length / 2
                    )[currIndex]
                })),
            color: "#43a84a",
            negativeColor: "#e64736"
        }

        setSentiGraphChange(() => {
            return {
                title: {
                    text: "Trending stocks by Change in Sentiment"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    styledmode: "on"
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                drawSentimentChangeGraph(
                                    arrB,
                                    tickerList,
                                    toggle,
                                    response
                                )
                            }
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                colors: ["#43a84a", "#f5d561"],
                lang: {
                    thousandsSep: ","
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: category,
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: SINGLE_GRAPH_DURATION,
                        plotLines: [
                            {
                                value: graphDataCount.changeBuzz ? null : 4.5,
                                width: 1,
                                color: "#aaa",
                                zIndex: 10
                            }
                        ],
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        title: {
                            text: "Average Change in Social Buzz",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        labels: {
                            formatter: function () {
                                return this.value + "%"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },

                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Change in Buzz volume : {point.y:,.2f}%</b><p>Social Media : {point.stPercent:,.2f}% | News : {point.newsPercent:,.2f}%</p></div>', // <br></br>Social Media value: {point.stAbs:,.2f} | News value: {point.newsAbs:,.2f}
                    // valueDecimal:1,
                    padding: 4
                },
                series: singleGraphData
            }
        })
    }

    const getGraphs = async () => {
        if (!isLoading) {
            setIsLoading(true)

            const date = graphDateTime
                ? dayjs(graphDateTime).format("YYYY-MM-DDTHH:mm:ssZ")
                : null

            await getGraphData({ graph_type: "social_buzz", date }).then(
                ({ data }) => {
                    const temp = findSeries(graphViews.buzz, data, 0, "buzz")

                    let tickerList = sortSeries(
                        temp["news"],
                        temp["st"],
                        "buzz"
                    )

                    const currentTickerList = graphDataCount.buzz
                        ? tickerList["all"]
                        : tickerList["top"]

                    const series = getSeriesTwo(
                        temp["news"],
                        temp["st"],
                        "Score",
                        graphDataCount.buzz,
                        tickerList
                    )

                    drawBuzzGraph(series, currentTickerList, data)
                }
            )

            await getGraphData({ graph_type: "sentiment", date }).then(
                ({ data }) => {
                    let temp = findSeries(
                        graphViews.sentiment,
                        data,
                        0,
                        "sentiment"
                    )

                    let tickerList = sortSeries(
                        temp["news"],
                        temp["st"],
                        "sentiment"
                    )
                    const currentTickerList = graphDataCount.buzz
                        ? tickerList["all"]
                        : tickerList["top"]

                    let toggle = graphDataCount.sentiment ? "all" : "top"

                    let series = getSeries(
                        temp["news"],
                        temp["st"],
                        null,
                        null,
                        "Score",
                        graphDataCount.sentiment,
                        tickerList
                    )

                    drawSentimentGraph(series, currentTickerList, toggle, data)
                }
            )

            await getGraphData({ graph_type: "social_buzz_change", date }).then(
                ({ data, dates }) => {
                    const temp = findSeries(
                        graphViews.changeBuzz,
                        data,
                        1,
                        "buzz_change"
                    )
                    if (datesList.length === 0) {
                        setDatesList(() => {
                            return dates
                        })
                    }

                    const tickerList = sortSeries(
                        temp["news"],
                        temp["st"],
                        "buzzChange"
                    )
                    const toggle = graphDataCount.changeBuzz ? "all" : "top"

                    const arr1 = getSeries(
                        temp["news"],
                        temp["st"],
                        temp["newsAbs"],
                        temp["stAbs"],
                        "Score",
                        graphDataCount.changeBuzz,
                        tickerList
                    )

                    drawBuzzChangeGraph(arr1, tickerList, toggle, data)
                }
            )

            await getGraphData({ graph_type: "sentiment_change", date }).then(
                ({ data }) => {
                    const temp = findSeries(graphViews.changeSentiment, data, 1)

                    const tickerList = sortSeries(
                        temp["news"],
                        temp["st"],
                        "sentimentChange"
                    )

                    const toggle = graphDataCount.changeSentiment
                        ? "all"
                        : "top"
                    const arr1 = getSeries(
                        temp["news"],
                        temp["st"],
                        temp["newsAbs"],
                        temp["stAbs"],
                        "Score",
                        graphDataCount.changeSentiment,
                        tickerList
                    )

                    drawSentimentChangeGraph(arr1, tickerList, toggle, data)
                }
            )

            setIsLoading(false)
        }
    }

    const setViews = (key, date) => {
        let datesKVPair = {
            one_day: datesList[1],
            one_week: datesList[2],
            two_week: datesList[3],
            one_month: datesList[4],
            two_month: datesList[5]
        }
        let datesKVPairChange = {
            one_day: datesList[7],
            one_week: datesList[2],
            two_week: datesList[3],
            one_month: datesList[4],
            two_month: datesList[5]
        }
        if (key === 1) {
            setGraphViews((prevState) => {
                return { ...prevState, buzz: date }
            })
            setDisplayDates((prevState) => {
                return {
                    ...prevState,
                    buzz:
                        Moment(datesKVPair[date]).format("DD/MMMM/YYYY") +
                        " > " +
                        Moment(datesList[1]).format("DD/MMMM/YYYY")
                }
            })
        } else if (key === 2) {
            setGraphViews((prevState) => {
                return { ...prevState, sentiment: date }
            })
            setDisplayDates((prevState) => {
                return {
                    ...prevState,
                    sentiment:
                        Moment(datesKVPair[date]).format("DD/MMMM/YYYY") +
                        " > " +
                        Moment(datesList[1]).format("DD/MMMM/YYYY")
                }
            })
        } else if (key === 3) {
            setGraphViews((prevState) => {
                return { ...prevState, changeBuzz: date }
            })
            setDisplayDates((prevState) => {
                return {
                    ...prevState,
                    changeBuzz:
                        Moment(datesKVPairChange[date]).format("DD/MMMM/YYYY") +
                        " > " +
                        Moment(datesList[1]).format("DD/MMMM/YYYY")
                }
            })
        } else if (key === 4) {
            setGraphViews((prevState) => {
                return { ...prevState, changeSentiment: date }
            })
            setDisplayDates((prevState) => {
                return {
                    ...prevState,
                    changeSentiment:
                        Moment(datesKVPairChange[date]).format("DD/MMMM/YYYY") +
                        " > " +
                        Moment(datesList[1]).format("DD/MMMM/YYYY")
                }
            })
        }
    }

    const setDataCount = (key, date) => {
        if (key === 1) {
            setGraphDataCount((prevState) => {
                return { ...prevState, buzz: date }
            })
        } else if (key === 2) {
            setGraphDataCount((prevState) => {
                return { ...prevState, sentiment: date }
            })
        } else if (key === 3) {
            setGraphDataCount((prevState) => {
                return { ...prevState, changeBuzz: date }
            })
        } else if (key === 4) {
            setGraphDataCount((prevState) => {
                return { ...prevState, changeSentiment: date }
            })
        }
    }

    const topBar = (key) => {
        let KVPair = ["buzz", "sentiment", "changeBuzz", "changeSentiment"]
        return (
            <div className='row mb-2 ' style={{}}>
                <div className='col-md-4' style={{ marginLeft: 2 }}>
                    <Stack direction='row' spacing={0.25} alignItems='center'>
                        <Typography>Top 10</Typography>
                        <Switch
                            inputProps={{ "aria-label": "ant design" }}
                            checked={graphDataCount[KVPair[key - 1]]}
                            onChange={(event) => {
                                setDataCount(key, event.target.checked)
                            }}
                        />
                        <Typography>All</Typography>
                    </Stack>
                </div>
                <ButtonGroup
                    className='col-md-auto'
                    style={{
                        textAlign: "right",
                        marginRight: "1px !important",
                        marginLeft: "auto",
                        paddingLeft: "auto"
                    }}
                    size='small'
                    aria-label='text button group'>
                    <Button
                        style={{
                            backgroundColor:
                                btnGroups[key - 1] === 1
                                    ? "#4b8fcd"
                                    : "lightGrey",
                            height: "2rem",
                            color: btnGroups[key - 1] === 1 ? "white" : "black"
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => {
                            setViews(key, "one_day")
                            let temp = btnGroups
                            temp[key - 1] = 1
                            setbtnGroups((prevState) => {
                                return temp
                            })
                        }}>
                        1 day
                    </Button>
                    <Button
                        style={{
                            backgroundColor:
                                btnGroups[key - 1] === 2
                                    ? "#4b8fcd"
                                    : "lightGrey",
                            height: "2rem",
                            color: btnGroups[key - 1] === 2 ? "white" : "black"
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => {
                            setViews(key, "one_week")
                            let temp = btnGroups
                            temp[key - 1] = 2
                            setbtnGroups((prevState) => {
                                return temp
                            })
                        }}>
                        7 days
                    </Button>
                    <Button
                        style={{
                            backgroundColor:
                                btnGroups[key - 1] === 3
                                    ? "#4b8fcd"
                                    : "lightGrey",
                            height: "2rem",
                            color: btnGroups[key - 1] === 3 ? "white" : "black"
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => {
                            setViews(key, "two_week")
                            let temp = btnGroups
                            temp[key - 1] = 3
                            setbtnGroups((prevState) => {
                                return temp
                            })
                        }}>
                        14 days
                    </Button>
                    <Button
                        style={{
                            backgroundColor:
                                btnGroups[key - 1] === 4
                                    ? "#4b8fcd"
                                    : "lightGrey",
                            height: "2rem",
                            color: btnGroups[key - 1] === 4 ? "white" : "black"
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => {
                            setViews(key, "one_month")
                            let temp = btnGroups
                            temp[key - 1] = 4
                            setbtnGroups((prevState) => {
                                return temp
                            })
                        }}>
                        30 days
                    </Button>
                    <Button
                        style={{
                            backgroundColor:
                                btnGroups[key - 1] === 5
                                    ? "#4b8fcd"
                                    : "lightGrey",
                            height: "2rem",
                            color: btnGroups[key - 1] === 5 ? "white" : "black"
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => {
                            setViews(key, "two_month")
                            let temp = btnGroups
                            temp[key - 1] = 5
                            setbtnGroups((prevState) => {
                                return temp
                            })
                        }}>
                        60 days
                    </Button>
                </ButtonGroup>
            </div>
        )
    }

    // Check Future Date
    const checkFutureData = (selectedDate) => {
        const tempDate = dayjs.tz(selectedDate, "America/New_York")
        const currentDate = dayjs().tz("America/New_York")

        if (tempDate.diff(currentDate, "days", true) < 0) {
            setGraphDateTime(tempDate)
        } else {
            alert("Enter a valid date")
        }
    }

    // Handle Change Date
    const handleGetGraphWithDate = async () => {
        await getGraphs()

        setDateChangeModal(() => {
            return false
        })
    }

    const getDisplayDates = () => {
        return Moment(dateJson["lastPulledDate"])
            .tz("America/New_York")
            .format("DD-MMM-YYYY ")
    }

    return (
        <div>
            <Modal
                aria-labelledby='transition-modal-title'
                aria-describedby='transition-modal-description'
                className={classes.modal}
                open={graphModalToggle}
                keepMounted
                onClose={() => {
                    setGraphToggle(false)
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
                style={{
                    width: "90%",
                    marginTop: "0",
                    maxHeight: "100%",
                    overflowY: "scroll"
                }}>
                <Slide
                    direction='up'
                    in={graphModalToggle}
                    mountOnEnter
                    unmountOnExit
                    style={{ transformOrigin: "0 0 0" }}
                    {...(graphModalToggle
                        ? { timeout: 300 }
                        : { timeout: 300 })}>
                    <div classes='container-fluid' className={classes.paper}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                classes={{
                                    root: classes.summaryRoot,
                                    content: classes.summaryContent
                                }}>
                                <div className='text-black text-xl font-bold'>
                                    Today's Alert | {getDisplayDates()}
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <DashboardAlert date={getDisplayDates()} />
                            </AccordionDetails>
                        </Accordion>
                        <div className='row my-4'>
                            <h3
                                className='col-3 transition-modal-description'
                                style={{ textAlign: "left" }}>
                                Trending Dashboards
                            </h3>
                            <div className='col-5' style={{ color: "gray" }}>
                                * As on Morning (Pre-Market):
                                <Button
                                    variant='text'
                                    style={{ color: "grey", fontSize: 16 }}
                                    size='medium'
                                    onClick={() => {
                                        setDateChangeModal(() => {
                                            return true
                                        })
                                    }}>
                                    {graphDateTime
                                        ? dayjs(graphDateTime)
                                              .tz("America/New_York")
                                              .format("DD-MMM-YYYY")
                                        : getDisplayDates() + ""}
                                </Button>
                                <Modal
                                    aria-labelledby='transition-modal-title'
                                    aria-describedby='transition-modal-description'
                                    className={classes.modal}
                                    open={dateChangeModal}
                                    onClose={() => {
                                        setDateChangeModal(() => {
                                            return false
                                        })
                                    }}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 500
                                    }}
                                    style={{ left: "35%", marginTop: "3%" }}>
                                    <div
                                        classes='container-fluid '
                                        className={classes.paper}
                                        style={{ maxWidth: "50%" }}>
                                        <div className='row'>
                                            <h2
                                                className='col-10 transition-modal-description'
                                                style={{ textAlign: "left" }}>
                                                Set ET Date & Time
                                            </h2>
                                            <div className='row'>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}>
                                                    <DateTimePicker
                                                        timezone='America/New_York'
                                                        value={graphDateTime}
                                                        onChange={(
                                                            newValue
                                                        ) => {
                                                            checkFutureData(
                                                                newValue
                                                            )
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                            <div className='row justify-content-center mt-3'>
                                                <Button
                                                    className='col-md-4 confirm-btn'
                                                    style={{
                                                        backgroundColor:
                                                            "#0d6efd",
                                                        color: "white"
                                                    }}
                                                    size='small'
                                                    variant='contained'
                                                    onClick={
                                                        handleGetGraphWithDate
                                                    }>
                                                    Confirm
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                            <div className='col-3'>
                                <a
                                    href='https://chatwithfinsoftai.streamlit.app/'
                                    target='_blank'
                                    rel='noreferrer'>
                                    <Button
                                        variant='contained'
                                        style={{
                                            backgroundColor:
                                                "rgb(75, 143, 205)",
                                            color: "white",
                                            fontSize: 16,
                                            textTransform: "none" // Prevent text transformation
                                        }}
                                        size='medium'
                                        disableElevation // Disable button elevation
                                    >
                                        ASK SSi Bot
                                    </Button>
                                </a>
                            </div>
                            <div
                                className='col-1'
                                style={{ textAlign: "right" }}>
                                <IconButton
                                    variant='outlined'
                                    onClick={() => {
                                        setGraphToggle(false)
                                    }}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                        <div
                            className='row justify-content-center'
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "1.1rem",
                                fontWeight: 400,
                                width: "100%",
                                padding: 0
                            }}>
                            <div className='col-md-6 mt-2'>
                                <FullScreen
                                    handle={screen1}
                                    onChange={getGraphs}>
                                    <Card
                                        style={
                                            screen1.active
                                                ? {
                                                      borderRadius: 20,
                                                      backgroundColor:
                                                          "#f4f4f4",
                                                      height: "100%",
                                                      overflowY: "scroll"
                                                  }
                                                : {
                                                      borderRadius: 20,
                                                      backgroundColor:
                                                          "rgba(229, 229, 229, 0.42)"
                                                  }
                                        }>
                                        <CardHeader
                                            title={
                                                <div
                                                    className='row ml-2'
                                                    style={{
                                                        padding:
                                                            "0px !important",
                                                        paddingTop: 5,
                                                        paddingBottom: 5
                                                    }}>
                                                    <h5 className='col-md-6'>
                                                        Trending stocks by
                                                        Social Buzz
                                                    </h5>
                                                </div>
                                            }
                                        />
                                        <CardContent>
                                            {/* {} */}
                                            {topBar(1)}
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={buzzGraph}
                                            />
                                        </CardContent>
                                    </Card>
                                </FullScreen>
                            </div>
                            <div className='col-md-6 mt-2'>
                                <FullScreen
                                    handle={screen2}
                                    onChange={getGraphs}>
                                    <Card
                                        style={
                                            screen2.active
                                                ? {
                                                      borderRadius: 20,
                                                      backgroundColor:
                                                          "#f4f4f4",
                                                      height: "100%",
                                                      overflowY: "scroll"
                                                  }
                                                : {
                                                      borderRadius: 20,
                                                      backgroundColor:
                                                          "rgba(229, 229, 229, 0.42)"
                                                  }
                                        }>
                                        <CardHeader
                                            title={
                                                <div
                                                    className='row ml-2'
                                                    style={{
                                                        padding:
                                                            "0px !important",
                                                        paddingTop: 5,
                                                        paddingBottom: 5
                                                    }}>
                                                    <h5 className='col-md-6'>
                                                        Trending stocks by
                                                        Sentiment
                                                    </h5>
                                                </div>
                                            }
                                        />
                                        <CardContent>
                                            {topBar(2)}

                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={sentiGraph}
                                            />
                                        </CardContent>
                                    </Card>
                                </FullScreen>
                            </div>
                            <div className='col-md-6 mt-2'>
                                <FullScreen
                                    handle={screen3}
                                    onChange={getGraphs}>
                                    <Card
                                        style={
                                            screen3.active
                                                ? {
                                                      borderRadius: 20,
                                                      backgroundColor:
                                                          "#f4f4f4",
                                                      height: "100%",
                                                      overflowY: "scroll"
                                                  }
                                                : {
                                                      borderRadius: 20,
                                                      backgroundColor:
                                                          "rgba(229, 229, 229, 0.42)"
                                                  }
                                        }>
                                        <CardHeader
                                            title={
                                                <div
                                                    className='row ml-2'
                                                    style={{
                                                        padding:
                                                            "0px !important",
                                                        paddingTop: 5,
                                                        paddingBottom: 1
                                                    }}>
                                                    <h5 className='col-md-12'>
                                                        Trending stocks by
                                                        Change in Social Buzz
                                                    </h5>
                                                </div>
                                            }
                                        />
                                        <CardContent>
                                            {topBar(3)}
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={buzzGraphChange}
                                            />
                                        </CardContent>
                                    </Card>
                                </FullScreen>
                            </div>
                            <div className='col-md-6 mt-2'>
                                <FullScreen
                                    handle={screen4}
                                    onChange={getGraphs}>
                                    <Card
                                        style={
                                            screen4.active
                                                ? {
                                                      borderRadius: 20,
                                                      backgroundColor:
                                                          "#f4f4f4",
                                                      height: "100%",
                                                      overflowY: "scroll"
                                                  }
                                                : {
                                                      borderRadius: 20,
                                                      backgroundColor:
                                                          "rgba(229, 229, 229, 0.42)"
                                                  }
                                        }>
                                        <CardHeader
                                            title={
                                                <div
                                                    className='row ml-2'
                                                    style={{
                                                        padding:
                                                            "0px !important",
                                                        paddingTop: 5,
                                                        paddingBottom: 5
                                                    }}>
                                                    <h5 className='col-md-12'>
                                                        Trending stocks by
                                                        Change in Sentiment
                                                    </h5>
                                                </div>
                                            }
                                        />
                                        <CardContent>
                                            {topBar(4)}

                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={sentiGraphChange}
                                            />
                                        </CardContent>
                                    </Card>
                                </FullScreen>
                            </div>
                            <div>
                                <Wordcloud
                                    date={
                                        graphDateTime
                                            ? dayjs(graphDateTime).format(
                                                  "YYYY-MM-DDTHH:mm:ssZ"
                                              )
                                            : null
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </Slide>
            </Modal>
        </div>
    )
}

export default TrendingGraphs
