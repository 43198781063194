// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 5%;
}

@media screen and (max-width: 600px) {
  .login_box {
    background: radial-gradient(circle, rgba(146, 201, 245, 1) 26%, rgba(128, 194, 244, 1) 52%, rgba(31, 143, 225, 1) 88%) !important;
    padding-right: 0px !important;
    align-items: center !important;
  }
}

.login__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #fff;
  padding: 60px;
  padding-top: 30px;
  border-radius: 15px;
  font-family: Poppins;
  line-height: 1.8;
}

.login__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  background-color: whitesmoke;
  border: solid 0px;
  border-radius: 5px;
}

.login__btn {
  width: 223px;
  height: 50px;
  background: #DDEDE1;
  border-radius: 5px;
  margin: auto;
  margin-bottom: 5px;
  color: #858585;
  border: solid 0px;


}

.inline-hr {
  display: inline-block;
  min-width: 25%;
  max-width: 40%;
  color: #858585;
  margin: 0 5px;
}

.login__google {
  background-color: #4285f4;
}

.login div {
  margin-top: 7px;
}`, "",{"version":3,"sources":["webpack://./src/components/static/Login.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,oBAAoB;EACpB,iBAAiB;AACnB;;AAEA;EACE;IACE,iIAAiI;IACjI,6BAA6B;IAC7B,8BAA8B;EAChC;AACF;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;EACtB,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,4BAA4B;EAC5B,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,cAAc;EACd,iBAAiB;;;AAGnB;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,cAAc;EACd,cAAc;EACd,aAAa;AACf;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".login {\n  height: 100vh;\n  width: 100vw;\n  display: flex;\n  align-items: center;\n  justify-content: end;\n  padding-right: 5%;\n}\n\n@media screen and (max-width: 600px) {\n  .login_box {\n    background: radial-gradient(circle, rgba(146, 201, 245, 1) 26%, rgba(128, 194, 244, 1) 52%, rgba(31, 143, 225, 1) 88%) !important;\n    padding-right: 0px !important;\n    align-items: center !important;\n  }\n}\n\n.login__container {\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  background-color: #fff;\n  padding: 60px;\n  padding-top: 30px;\n  border-radius: 15px;\n  font-family: Poppins;\n  line-height: 1.8;\n}\n\n.login__textBox {\n  padding: 10px;\n  font-size: 18px;\n  margin-bottom: 10px;\n  background-color: whitesmoke;\n  border: solid 0px;\n  border-radius: 5px;\n}\n\n.login__btn {\n  width: 223px;\n  height: 50px;\n  background: #DDEDE1;\n  border-radius: 5px;\n  margin: auto;\n  margin-bottom: 5px;\n  color: #858585;\n  border: solid 0px;\n\n\n}\n\n.inline-hr {\n  display: inline-block;\n  min-width: 25%;\n  max-width: 40%;\n  color: #858585;\n  margin: 0 5px;\n}\n\n.login__google {\n  background-color: #4285f4;\n}\n\n.login div {\n  margin-top: 7px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
