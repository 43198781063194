import "bootstrap/dist/css/bootstrap.min.css"
import React, { useState, useEffect } from "react"
import logo from "./static/ssi-research-logo.png"
import compositeSentImg from "./static/composite_sent.png"
import pieChartsImg from "./static/piecharts.png"
import graphsImg from "./static/charts.png"
import tablesImg from "./static/tables.png"
import TrendingGraphs from "./TrendingGraphs"
import {
    Button,
    FormControl,
    Select,
    Modal,
    IconButton,
    Backdrop,
    MenuItem,
    Menu,
    Snackbar,
    SnackbarContent
} from "@material-ui/core"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import PersonIcon from "@material-ui/icons/Person"
import axios from "axios"
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk"
import HelpOutlineIcon from "@material-ui/icons/HelpOutline"
import CloseIcon from "@material-ui/icons/Close"
import { NavLink } from "react-router-dom"
import { Role } from "./userManagementUI/src/_helpers"
import { useAuthState } from "react-firebase-hooks/auth"
import { useNavigate } from "react-router-dom"
import { accountService } from "./userManagementUI/src/_services"
import DisplaySettingsOutlinedIcon from "@mui/icons-material/DisplaySettingsOutlined"
import {
    Card,
    Slide,
    CardContent,
    CardHeader,
    Typography,
    CardMedia,
    Switch,
    ButtonGroup,
    Stack,
    Chip,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material"
import { auth, logout, storeShareLogs } from "./firebase"
import Highcharts, { useSerialIds } from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined"
import indicatorsAll from "highcharts/indicators/indicators-all"
import annotationsAdvanced from "highcharts/modules/annotations-advanced"
import priceIndicator from "highcharts/modules/price-indicator"
import fullScreen from "highcharts/modules/full-screen"
import Moment from "moment"
import wordCloud from "highcharts/modules/wordcloud.js"

// import "highcharts/css/stocktools/gui.css";
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import "highcharts/css/annotations/popup.css"

wordCloud(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
indicatorsAll(Highcharts)
annotationsAdvanced(Highcharts)
priceIndicator(Highcharts)
fullScreen(Highcharts)

let initialStockOptions = {
    buzz: {
        chart: {
            type: "bar"
        },
        plotOptions: {
            series: {
                stacking: "normal"
            },
            visible: false
        },
        credits: {
            text: "finsoftai.com",
            href: "http://www.finsoftai.com",
            style: {
                fontSize: 12
            },
            position: {
                align: "right",
                x: -10
            }
        },
        stockTools: {
            gui: {
                enabled: false // disable the built-in toolbar
            }
        },
        xAxis: [
            {
                title: {
                    text: "Tickers",
                    style: { fontWeight: "bold", fontSize: "13px" }
                }
            }
        ],

        yAxis: [
            {
                title: {
                    text: "Buzz Volume",
                    style: { fontWeight: "bold", fontSize: "13px" }
                }
            }
        ],
        title: {
            text: ""
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen"]
                }
            }
        },
        series: [
            {
                data: [{ x: 1, y: 13 }]
            }
        ]
    },
    sentiment: {
        chart: {
            type: "bar",
            styledmode: "on"
        },
        credits: {
            text: "finsoftai.com",
            href: "http://www.finsoftai.com",
            style: {
                fontSize: 12
            },
            position: {
                align: "right",
                x: -10
            }
        },
        stockTools: {
            gui: {
                enabled: false // disable the built-in toolbar
            }
        },
        xAxis: [
            {
                title: {
                    text: "Tickers",
                    style: { fontWeight: "bold", fontSize: "13px" }
                }
            }
        ],

        yAxis: [
            {
                title: {
                    text: "sentiment",
                    style: { fontWeight: "bold", fontSize: "13px" }
                }
            }
        ],
        title: {
            text: ""
        },

        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen"]
                }
            }
        },

        labels: {
            formatter: function () {
                return Highcharts.numberFormat(this.value, 2)
            }
        },
        series: [
            {
                name: "Tickers",
                data: [
                    0.481, 0.262, 0.253, 0.215, 0.131, 0.112, 0.94, -0.35, -0.3,
                    -0.8
                ]
            }
        ]
    },
    change: {
        chart: {
            type: "bar",
            styledmode: "on"
        },
        credits: {
            text: "finsoftai.com",
            href: "http://www.finsoftai.com",
            style: {
                fontSize: 12
            },
            position: {
                align: "right",
                x: -10
            }
        },
        stockTools: {
            gui: {
                enabled: false // disable the built-in toolbar
            }
        },
        xAxis: [
            {
                title: {
                    text: "Tickers",
                    style: { fontWeight: "bold", fontSize: "13px" }
                }
            }
        ],

        yAxis: [
            {
                title: {
                    text: "sentiment",
                    style: { fontWeight: "bold", fontSize: "13px" }
                }
            }
        ],
        title: {
            text: ""
        },

        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen"]
                }
            }
        },

        labels: {
            formatter: function () {
                return Highcharts.numberFormat(this.value, 2)
            }
        },
        series: [
            {
                name: "Tickers",
                data: [
                    0.481, 0.262, 0.253, 0.215, 0.131, 0.112, 0.94, -0.35, -0.3,
                    -0.8
                ],
                color: "#43a84a",
                negativeColor: "#e64736",

                type: "bar",
                pointStart: 0
            }
        ]
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white !important"
    },
    icon: {
        fill: "blue"
    },
    outlined: {
        backgroundColor: "blue",
        height: "100%"
    },
    selectMenu: {
        color: "black !important"
    },
    modal: {
        display: "flex",
        width: "100% !important",
        alignItems: "center",
        justifyContent: "center"
    },
    modalTwo: {
        margin: "auto",
        marginTop: "5%",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 2)
        // maxWidth: "80% !important"
    },
    paperTwo: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 3, 2),
        paddingRight: 0
    },
    fullscreenBackground: {
        border: "solid 2px red !important",
        height: "100% !important"
    }
}))

const sortSeries = (data1, data2, key) => {
    let multiplier =
        (key === "sentiment") | (key === "sentimentChange") ? 0.5 : 1
    const dataList = Object.keys(data1).map((key) => {
        return {
            value: multiplier * data1[key] + multiplier * data2[key],
            name: key,
            d1: data1[key],
            d2: data2[key]
        }
    })
    dataList.sort((a, b) => (a.value < b.value ? 1 : -1)).splice(30, 1)

    if (key === "buzz") {
        //sortSeries

        let tickerList = dataList.map((t) => t.name)
        return tickerList
    } else if (key === "buzzChange") {
        //sortChangeSeries
        let tickerList = dataList.map((t) => t.name)
        let topTickerList = tickerList
            .slice(0, 5)
            .concat(tickerList.slice(25, 30))
        return { all: tickerList, top: topTickerList }
    } else if ((key === "sentiment") | (key === "sentimentChange")) {
        //sortSentimentSeries
        let tickerList = dataList.map((t) => t.name)
        let topTickerList = tickerList
            .slice(0, 5)
            .concat(tickerList.slice(25, 30))
        return { all: tickerList, top: topTickerList }
    }
}

const getSeries = (data1, data2, source, dataToggle, tickerList) => {
    let toggle = dataToggle ? "all" : "top"
    let temp = tickerList[toggle].map((key) => {
        return parseFloat(
            ((0.5 * data1[key] + 0.5 * data2[key]) / 1).toFixed(3)
        )
    })
    return {
        name: source,
        type: "bar",
        data: dataToggle ? temp : temp.slice(0, 11),
        pointStart: 0,
        color: "#43a84a",
        negativeColor: "#e64736"
    }
}

const getSeriesTwo = (data, source, dataToggle, tickerList) => {
    let temp = tickerList.map((key) => {
        return data[key]
    })
    return {
        name: source,
        data: dataToggle ? temp : temp.slice(0, 11)
    }
}

const getSeriesChangeTwo = (data, source, dataToggle, tickerList) => {
    let temp = tickerList.map((key) => {
        return data[key]
    })
    return {
        name: source,
        data: dataToggle ? temp : temp.slice(0, 11)
    }
}

const findSeries = (Sname, data, changeFlag) => {
    let data1 = null
    let data2 = null
    if (changeFlag) {
        data1 = data.find((o) => o.fields === Sname + "_st_change")
        data2 = data.find((o) => o.fields === Sname + "_news_change")
        return { news: data2, st: data1 }
    } else {
        data1 = data.find((o) => o.fields === Sname + "_st")
        data2 = data.find((o) => o.fields === Sname + "_news")

        return { news: data2, st: data1 }
    }
}

const Navbar = (props) => {
    const [queryName, setQueryName] = useState("")
    const [queryText, setQueryText] = useState("")
    const [queryVersion, setQueryVersion] = useState(0)
    const [modalToggle, setToggle] = useState(false)
    const [graphModalToggle, setGraphToggle] = useState(true)
    const [screenModalToggle, setScreenModalToggle] = useState(false)
    // const [user, setUser] = useState({})
    const [parametersToggle, setParameters] = React.useState("general")
    const [screensOnDisplay, setDisplayScreens] = useState({
        composite: true,
        piecharts: true,
        graph: true,
        tables: true
    })
    const [graphViews, setGraphViews] = useState({
        buzz: "one_day",
        sentiment: "one_day",
        changeBuzz: "one_day",
        changeSentiment: "one_day"
    })
    const [graphDataCount, setGraphDataCount] = useState({
        buzz: false,
        sentiment: false,
        changeBuzz: false,
        changeSentiment: false
    })
    const [buzzGraph, setBuzzGraph] = useState(initialStockOptions["buzz"])
    const [buzzGraphChange, setBuzzGraphChange] = useState(
        initialStockOptions["sentiment"]
    )
    const [sentiGraph, setSentiGraph] = useState(
        initialStockOptions["sentiment"]
    )
    const [sentiGraphChange, setSentiGraphChange] = useState(
        initialStockOptions["change"]
    )
    const [datesList, setDatesList] = useState([])
    const [displayDates, setDisplayDates] = useState({
        buzz: "",
        sentiment: "",
        changeBuzz: "",
        changeSentiment: ""
    })
    const [updatedDate, setUpdatedDate] = useState(null)
    const [user, loading, error] = useAuthState(auth)
    const history = useNavigate()

    const fetchNames = async () => {
        try {
            let resp = await axios.get(
                `${process.env.REACT_APP_DATA_SERVER_API_URL}/queries/names`,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Content-Type, Authorization"
                    }
                }
            )
            return resp.data
        } catch (err) {
            console.log("err", err)
        }
    }

    const [btnGroups, setbtnGroups] = useState([1, 1, 1, 1])
    const [alertState, setAlertState] = React.useState({
        open: false,
        vertical: "top",
        horizontal: "center",
        severity: "",
        message: ""
    })

    // const screen1 = useFullScreenHandle();
    // const screen2 = useFullScreenHandle();
    // const screen3 = useFullScreenHandle();
    // const screen4 = useFullScreenHandle();

    // const topBar = (key) =>{
    //   let KVPair = ['buzz','sentiment','changeBuzz','changeSentiment']
    //   return <div className="row mb-2 " style={{ }}>
    //     <div className='col-md-4' style={{marginLeft:2}}>
    //       <Stack direction="row" spacing={0.25} alignItems="center">
    //         <Typography>Top 10</Typography>
    //         <Switch inputProps={{ 'aria-label': 'ant design' }} checked={graphDataCount[KVPair[key - 1]]} onChange={(event) => {  setDataCount(key, event.target.checked) }} />
    //         <Typography>All</Typography>
    //       </Stack>
    //     </div>
    //     <ButtonGroup className='col-md-auto' style={{ textAlign: "right", marginRight:"1px !important", marginLeft:"auto", paddingLeft:"auto"}} size="small"  aria-label="text button group">
    //       <Button style={{backgroundColor:(btnGroups[key - 1] === 1 ? "#4b8fcd" : "lightGrey" ),color:(btnGroups[key - 1] === 1 ? "white" : "black")}} size="small" variant="contained" onClick={() => { setViews(key, "one_day"); let temp = btnGroups; temp[key - 1] = 1; setbtnGroups((prevState) => { return temp }) }} >1 day</Button>
    //       <Button style={{backgroundColor:(btnGroups[key - 1] === 2 ? "#4b8fcd" : "lightGrey" ),color:(btnGroups[key - 1] === 2 ? "white" : "black")}} size="small" variant="contained" onClick={() => { setViews(key, "one_week"); let temp = btnGroups; temp[key - 1] = 2; setbtnGroups((prevState) => { return temp }) }}>7 days</Button>
    //       <Button style={{backgroundColor:(btnGroups[key - 1] === 3 ? "#4b8fcd" : "lightGrey" ),color:(btnGroups[key - 1] === 3 ? "white" : "black")}}  size="small" variant="contained" onClick={() => { setViews(key, "two_week"); let temp = btnGroups; temp[key - 1] = 3; setbtnGroups((prevState) => { return temp }) }}>14 days</Button>
    //       <Button style={{backgroundColor:(btnGroups[key - 1] === 4 ? "#4b8fcd" : "lightGrey" ),color:(btnGroups[key - 1] === 4 ? "white" : "black")}} size="small" variant="contained" onClick={() => { setViews(key, "one_month"); let temp = btnGroups; temp[key - 1] = 4; setbtnGroups((prevState) => { return temp }) }}>30 days</Button>
    //       <Button style={{backgroundColor:(btnGroups[key - 1] === 5 ? "#4b8fcd" : "lightGrey" ),color:(btnGroups[key - 1] === 5 ? "white" : "black")}} size="small" variant="contained" onClick={() => { setViews(key, "two_month"); let temp = btnGroups; temp[key - 1] = 5; setbtnGroups((prevState) => { return temp }) }}>60 days</Button>
    //     </ButtonGroup>

    //   </div>

    // }

    const handleAlertOpen = (newState) => {
        setAlertState({ alertOpen: true, ...newState })
    }

    const handleAlertClose = () => {
        setAlertState({ ...alertState, alertOpen: false })
    }

    const setScreens = (source, event) => {
        // Get the previous state of display screens
        setDisplayScreens((prevState) => {
            // Update the state based on the source of the event
            const updatedState = {
                ...prevState,
                [source]: event.target.checked
            }

            // Count the number of enabled screens
            const enabledScreensCount = Object.values(updatedState).filter(
                (screen) => screen
            ).length

            // If no screens are enabled, prevent disabling the current screen
            if (enabledScreensCount === 0 && !event.target.checked) {
                handleAlertOpen({
                    vertical: "top",
                    horizontal: "center",
                    message: "At least one output panel must be enabled",
                    contentStyle: {
                        backgroundColor: "#008080",
                        fontSize: 18,
                        marginTop: "20%"
                    }
                })
                return prevState // Revert to the previous state
            }

            // Otherwise, update the state with the new value
            return updatedState
        })
    }

    const confirmScreens = () => {
        props.setFinalScreens(screensOnDisplay)
        handleScreenModalClose()
    }
    const fetchQuery = async () => {
        try {
            let resp = await axios.post(
                `${process.env.REACT_APP_DATA_SERVER_API_URL}/queries/params`,
                {
                    data: {
                        query_name: queryName,
                        query_version: queryVersion
                    }
                },
                { headers: { "Access-Control-Allow-Origin": "*" } }
            )

            setTimeout(() => {
                props.querySetter(resp.data)
            }, 100)

            return resp.data
        } catch (err) {
            console.log(err)
        }
    }
    const handleModalOpen = () => {
        setToggle(true)
    }

    // const setViews = (key, date) =>{
    //   let datesKVPair = {"one_day":datesList[1],"one_week":datesList[2],"two_week":datesList[3],"one_month":datesList[4],"two_month":datesList[5]}
    //   let datesKVPairChange = {"one_day":datesList[7],"one_week":datesList[2],"two_week":datesList[3],"one_month":datesList[4],"two_month":datesList[5]}
    //   if (key===1){
    //     setGraphViews((prevState)=>{return {...prevState, buzz:date}})
    //     setDisplayDates((prevState)=>{return {...prevState, buzz:Moment(datesKVPair[date]).format('DD/MMMM/YYYY')+" > "+ Moment(datesList[1]).format('DD/MMMM/YYYY')}})
    //   }
    //   else if (key===2){
    //     setGraphViews((prevState)=>{return {...prevState, sentiment:date}})
    //     setDisplayDates((prevState)=>{return {...prevState, sentiment:Moment(datesKVPair[date]).format('DD/MMMM/YYYY')+" > "+ Moment(datesList[1]).format('DD/MMMM/YYYY')}})

    //   }
    //   else if (key===3){
    //     setGraphViews((prevState)=>{return {...prevState, changeBuzz:date}})
    //     setDisplayDates((prevState)=>{return {...prevState, changeBuzz:Moment(datesKVPairChange[date]).format('DD/MMMM/YYYY')+" > "+ Moment(datesList[1]).format('DD/MMMM/YYYY')}})
    //   }
    //   else if (key===4){
    //     setGraphViews((prevState)=>{return {...prevState, changeSentiment:date}})
    //     setDisplayDates((prevState)=>{return {...prevState, changeSentiment:Moment(datesKVPairChange[date]).format('DD/MMMM/YYYY')+" > "+ Moment(datesList[1]).format('DD/MMMM/YYYY')}})
    //   }
    // }

    // const setDataCount = (key, date) =>{

    //   if (key===1){
    //     setGraphDataCount((prevState)=>{return {...prevState, buzz:date}})
    //   }
    //   else if (key===2){
    //     setGraphDataCount((prevState)=>{return {...prevState, sentiment:date}})
    //   }
    //   else if (key===3){
    //     setGraphDataCount((prevState)=>{return {...prevState, changeBuzz:date}})
    //   }
    //   else if (key===4){
    //     setGraphDataCount((prevState)=>{return {...prevState, changeSentiment:date}})
    //   }
    // }

    const handleModalClose = () => {
        setToggle(false)
    }

    const handleGraphModalOpen = () => {
        setGraphToggle(true)
    }

    const handleGraphModalClose = () => {
        setGraphToggle(false)
    }

    const handleScreenModalOpen = () => {
        setScreenModalToggle(true)
    }

    const handleScreenModalClose = () => {
        setScreenModalToggle(false)
    }

    const handleChange = async (event) => {
        setQueryText(event.target.value)
        setQueryVersion(event.target.value.split("||")[1])
        setQueryName(event.target.value.split("||")[0])
        console.log(event.target.value, "ETV")
    }
    const classes = useStyles()
    let queryNameList = []
    const [queryOptions, setOptions] = useState([])
    const [queryOptionsAuto, setOptionsAuto] = useState([])

    const Options = async () => {
        queryNameList = await fetchNames()
        if (queryNameList.message) return

        let temp = await queryNameList.map((name) => (
            <option
                value={name.query_name + "||" + name.query_version}
                style={{
                    fontSize: 24,
                    paaddingTop: 2,
                    paddingBottom: 2,
                    fontFamily: "Verdana, Arial",
                    border: "solid 1px red !important"
                }}>
                {name.query_name} (v{name.query_version})
            </option>
        ))
        let new_temp = await queryNameList.map((name) => {
            return {
                label: name.query_name + " (v" + name.query_version + ")",
                value: name.query_name + "||" + name.query_version
            }
        })
        setOptions(temp)
        setOptionsAuto(new_temp)
    }

    const handleParametersChange = (event, newAlignment) => {
        setParameters(newAlignment)
        props.parameterFlag(newAlignment)
    }

    useEffect(() => {
        queryNameList = fetchNames()
        Options()
        try {
            // const subscription = accountService.user.subscribe((x) =>
            //     setUser((t) => {
            //         return x
            //     })
            // )
        } catch (err) {
            console.log(err, "4")
        }
        props.setUpdates(false)
    }, [props.updateQuery])

    useEffect(() => {
        fetchQuery()
    }, [queryName, queryText])

    useEffect(() => {}, [screensOnDisplay, parametersToggle])
    useEffect(() => {
        console.log(props.dailyModal)
    }, [props.dailyModal])

    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(() => {
            return null
        })
    }
    const setTour = async () => {
        setQueryText("JPMorgan stocks ||1")
        setQueryVersion("1")
        setQueryName("JPMorgan stocks ")
        setTimeout(() => {
            props.setRunHelp(true)
        }, 100)
    }
    // useEffect(()=>{if (props.dailyModal){
    //   setGraphToggle(()=>{return true});
    // }},[props.dailyModal])
    // useEffect(()=>{props.setDailyModal(graphModalToggle)},[graphModalToggle])
    // const getGraphs = async() =>{

    //   await api.get_graphs("social_buzz").then((data)=>{
    //     let temp = null
    //     try{

    //       temp= findSeries(graphViews.buzz, JSON.parse(data).data, 0,"buzz")
    //     }
    //     catch (r){

    //       temp= findSeries(graphViews.buzz, data.data,0,"buzz")
    //     }
    //     // let tickerList = sortSeries(temp["news"],temp["st"])
    //     let tickerList = sortSeries(temp["news"],temp["st"],"buzz")

    //     let toggle = graphDataCount.changeSentiment?"all":"top"

    //     let arr1 = getSeriesTwo(temp["news"],"News", graphDataCount.buzz,tickerList)
    //     let arr2 = getSeriesTwo(temp["st"],"Social Media", graphDataCount.buzz,tickerList)
    //     let arrB = [arr1,arr2]
    //     setBuzzGraph(()=>{return {
    //       chart: {
    //         type: "bar",
    //         height:screen1.active?"40%":"70%"
    //       },
    //       colors:["#1f73c1", "#f5d561"],
    //       legend: {
    //         enabled: false,
    //         title: {
    //           text: "",
    //           style: {
    //               fontStyle: 'italic'
    //           },

    //       },
    //     },
    //       plotOptions: {
    //         series: {
    //           stacking: "normal"
    //         },
    //         // visible: false,
    //       },
    //       credits: {
    //         text: "finsoftai.com",
    //         href: "http://www.finsoftai.com",
    //         style: {
    //           fontSize: 12
    //         },
    //         position: {
    //           align: 'right',
    //           x: -10
    //         }
    //       },
    //       stockTools: {
    //         gui: {
    //             enabled: false // disable the built-in toolbar
    //         }
    //     },
    //       xAxis: [
    //         {
    //           title: {
    //             text: "Tickers",
    //             style: { fontWeight: "bold", fontSize: "15px" }
    //           },
    //           categories:tickerList
    //         }],

    //         yAxis: [
    //           {
    //             title: {
    //               text: "Social Volume",
    //               style: { fontWeight: "bold", fontSize: "15px" }
    //             },

    //           },
    //         ],
    //         title:{
    //           text:""
    //         },
    //       exporting: {
    //         buttons: {
    //           contextButton: {
    //             menuItems: [
    //               "viewFullscreen"
    //             ]
    //           }
    //         }
    //       },
    //       tooltip: {
    //         pointFormat: "{point.y:.2f} mm"
    //     },
    //       series:  arrB
    //     }})

    //   })

    //   await api.get_graphs("sentiment").then((data)=>{
    //     let temp = null
    //     try{

    //       temp= findSeries(graphViews.sentiment, JSON.parse(data).data,0,"sentiment")
    //     }
    //     catch (r){
    //       console.log(r)
    //       temp= findSeries(graphViews.sentiment, data.data,0,"sentiment")
    //     }
    //     // let tickerList = sortSentimentSeries(temp["news"],temp["st"])
    //     let tickerList = sortSeries(temp["news"],temp["st"],"sentiment")

    //     let toggle = graphDataCount.sentiment?"all":"top"
    //     let arr1 = getSeries(temp["news"],temp["st"],"Score", graphDataCount.sentiment,tickerList)
    //     let arrB = arr1
    //     setSentiGraph(()=>{return {
    //       chart: {
    //         type: "bar",

    //         styledmode:"on",
    //         height:screen2.active?"40%":"70%"

    //       },
    //       legend: {
    //         enabled: false
    //     },

    //       credits: {
    //         text: "finsoftai.com",
    //         href: "http://www.finsoftai.com",
    //         style: {
    //           fontSize: 12
    //         },
    //         position: {
    //           align: 'right',
    //           x: -10
    //         }
    //       },
    //       stockTools: {
    //         gui: {
    //             enabled: false // disable the built-in toolbar
    //         }
    //     },
    //       xAxis: [
    //         {
    //           title: {
    //             text: "Tickers",
    //             style: { fontWeight: "bold", fontSize: "15px" }
    //           },
    //           categories:tickerList[toggle],
    //             plotLines: [{
    //               value: graphDataCount.sentiment?null:4.5,
    //               width: 1,
    //               color: '#aaa',
    //               zIndex: 10
    //             }],
    //         }],
    //       // legend:{enabled:false},
    //         yAxis: [
    //           {
    //             title: {
    //               text: "Sentiment Score",
    //               style: { fontWeight: "bold", fontSize: "15px" }
    //             }
    //           },
    //         ],
    //         title:{
    //           text:""
    //         },

    //       exporting: {
    //         buttons: {
    //           contextButton: {
    //             menuItems: [
    //               "viewFullscreen"
    //             ]
    //           }
    //         }
    //       },
    //       tooltip: {
    //         pointFormat: "{point.y:.2f} mm"
    //     },

    //       series: arrB
    //     }
    //     })

    //      })

    //   await api.get_graphs("social_buzz_change").then((data)=>{
    //     let temp = null

    //     try{
    //       temp= findSeries(graphViews.changeBuzz,JSON.parse(data).data,1,"buzz_change")
    //       if(datesList.length===0){setDatesList(()=>{return JSON.parse(data).dates})}

    //     }
    //     catch (r){

    //       temp= findSeries(graphViews.changeBuzz, data.data,1,"buzz_change")
    //       if(datesList.length===0){  setDatesList(()=>{return data.dates})}

    //     }
    //     // let tickerList = sortChangeSeries(temp["news"],temp["st"])
    //     let tickerList = sortSeries(temp["news"],temp["st"],"buzzChange")
    //     let toggle = graphDataCount.changeBuzz?"all":"top"

    //     let arr1 = getSeriesChangeTwo(temp["news"],"News", graphDataCount.changeBuzz,tickerList[toggle])
    //     let arr2 = getSeriesChangeTwo(temp["st"],"Social Media", graphDataCount.changeBuzz,tickerList[toggle])
    //     let arrBTWO = [arr1,arr2]

    //     setBuzzGraphChange(()=>{return {
    //       chart: {
    //         type: "bar",
    //         // spacing: [24, 24, 24, 24]
    //         styledmode:"on",
    //         height:screen3.active?"40%":"70%"
    //       },
    //       legend: {
    //         enabled: false
    //     },
    //     colors:["#1f73c1", "#f5d561"],

    //       credits: {
    //         text: "finsoftai.com",
    //         href: "http://www.finsoftai.com",
    //         style: {
    //           fontSize: 12
    //         },
    //         position: {
    //           align: 'right',
    //           x: -10
    //         }
    //       },
    //       stockTools: {
    //         gui: {
    //             enabled: false // disable the built-in toolbar
    //         }
    //     },
    //       xAxis: [
    //         {
    //           title: {
    //             text: "Tickers",
    //             style: { fontWeight: "bold", fontSize: "15px" }
    //           },
    //           categories:tickerList[toggle],
    //           plotLines: [{
    //             value: graphDataCount.changeBuzz?null:4.5,
    //             width: 1,
    //             color: '#aaa',
    //             zIndex: 10
    //           }],
    //         }],
    //       // legend:{enabled:false},
    //         yAxis: [
    //           {
    //             title: {
    //               text: "Change in Social Volume",
    //               style: { fontWeight: "bold", fontSize: "15px" }
    //             }
    //           }],
    //         title:{
    //           text:""
    //         },

    //       exporting: {
    //         buttons: {
    //           contextButton: {
    //             menuItems: [
    //               "viewFullscreen"
    //             ]
    //           }
    //         }
    //       },

    //       labels: {
    //         formatter: function () {
    //           return Highcharts.numberFormat(this.value, 2);
    //         },
    //       },
    //       series: arrBTWO
    //     }
    //     })

    //   })
    //   await api.get_graphs("sentiment_change").then((data)=>{
    //     let temp = null

    //     try{
    //       temp= findSeries(graphViews.changeSentiment, JSON.parse(data).data,1)
    //     }
    //     catch (r){

    //       temp= findSeries(graphViews.changeSentiment, data.data,1)
    //     }
    //     // let tickerList = sortSentimentSeries(temp["news"],temp["st"])
    //     let tickerList = sortSeries(temp["news"],temp["st"], "sentimentChange")

    //     let toggle = graphDataCount.changeSentiment?"all":"top"
    //     let arr1 = getSeries(temp["news"],temp["st"],"Score", graphDataCount.changeSentiment,tickerList)
    //     let arrB = arr1

    //     setSentiGraphChange(()=>{return {
    //       chart: {
    //         type: "bar",
    //         // spacing: [24, 24, 24, 24]
    //         styledmode:"on",
    //         height:screen4.active?"40%":"70%"

    //              },
    //       legend: {
    //         enabled: false
    //              },
    //       "tooltip": {
    //         "pointFormat": "{point.y:.2f} mm"
    //     },
    //       credits: {
    //         text: "finsoftai.com",
    //         href: "http://www.finsoftai.com",
    //         style: {
    //           fontSize: 12
    //         },
    //         position: {
    //           align: 'right',
    //           x: -10
    //         }
    //       },
    //       stockTools: {
    //         gui: {
    //             enabled: false // disable the built-in toolbar
    //         }
    //     },
    //       xAxis: [
    //         {
    //           title: {
    //             text: "Tickers",
    //             style: { fontWeight: "bold", fontSize: "15px" }
    //           },
    //           categories:tickerList[toggle],
    //           plotLines: [{
    //             value: graphDataCount.changeSentiment?null:4.5,
    //             width: 1,
    //             color: '#aaa',
    //             zIndex: 10
    //           }],
    //         }],
    //       // legend:{enabled:false},
    //         yAxis: [
    //           {
    //             title: {
    //               text: "Change in Sentiment Score",
    //               style: { fontWeight: "bold", fontSize: "15px" }
    //             }
    //           }],
    //         title:{
    //           text:""
    //         },

    //       exporting: {
    //         buttons: {
    //           contextButton: {
    //             menuItems: [
    //               "viewFullscreen"
    //             ]
    //           }
    //         }
    //       },
    //       series: arrB
    //     }
    //     })

    //     // getSeries(data[0])
    //   })

    // }

    // useEffect(()=>{

    // }, [buzzGraph, buzzGraphChange,sentiGraph,sentiGraphChange])

    //   useEffect(()=>{
    // setViews(1,"one_day");
    // setViews(2,"one_day");
    // setViews(3,"one_day");
    // setViews(4,"one_day");
    // let newDate = new Date();
    // let date = newDate.getDate();
    // setUpdatedDate(()=>{return Moment(newDate).format('DD/MMMM/YYYY');})
    //   }, [datesList])

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: alertState.vertical,
                    horizontal: alertState.horizontal
                }}
                open={alertState.alertOpen}
                onClose={handleAlertClose}
                autoHideDuration={2000}
                key={alertState.vertical + alertState.horizontal}
                severity='info'
                bodyStyle={{ backgroundColor: "teal", color: "coral" }}>
                <SnackbarContent
                    message={alertState.message}
                    style={alertState.contentStyle}></SnackbarContent>
            </Snackbar>
            <div
                className='navbar navbar-expand-lg navbar-light'
                style={{ backgroundColor: "white" }}>
                <div className='container-fluid'>
                    <div>
                        <Modal
                            aria-labelledby='transition-modal-title'
                            aria-describedby='transition-modal-description'
                            className={classes.modal}
                            open={modalToggle}
                            keepMounted
                            onClose={handleModalClose}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500
                            }}>
                            <div
                                classes='container-fluid '
                                className={classes.paper}
                                style={{ maxWidth: "70%" }}>
                                <div className='row'>
                                    <h2
                                        className='col-10 transition-modal-description'
                                        style={{ textAlign: "left" }}>
                                        About SST Research
                                    </h2>
                                    <div
                                        className='col-2'
                                        style={{ textAlign: "right" }}>
                                        <IconButton
                                            variant='outlined'
                                            onClick={() => {
                                                handleModalClose()
                                            }}>
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                </div>
                                <hr></hr>
                                <div
                                    className='row'
                                    style={{
                                        textAlign: "left",
                                        fontFamily: "Poppins",
                                        display: "inline-block",
                                        fontSize: "1.1rem",
                                        fontWeight: 400
                                    }}>
                                    <p>
                                        <b>FinSoftAi</b> is developing a suite
                                        of products for Social Trading. Each
                                        product works stand-alone, while
                                        seamlessly interoperating with other
                                        products in the{" "}
                                        <b>
                                            Social Sentiment Insights (SSI)
                                            suite
                                        </b>
                                        . Institutional and Retail Customers can
                                        pick and choose what they want based on
                                        their growing Social Trading
                                        requirements. Planned products are SST
                                        Beat, SST Research, SST Insights, SST
                                        Enterprise and SST Consortium.
                                    </p>
                                    <p>
                                        <b>SST Research</b> enables Equity
                                        Research Analysts, Portfolio Managers
                                        and Traders make the right investment
                                        decisions based on the market pulse. SST
                                        Research filters and uses Machine
                                        Learning to process a huge volume of
                                        news and social media to provide a
                                        detailed analysis of overall and event
                                        specific sentiment for supported stocks.
                                        The analyst can slice and dice sentiment
                                        by source and date range to visualize
                                        the aggregated sentiment and social buzz
                                        (news and tweet volume),while also
                                        charting Sentiment over time versus
                                        stock price, stock volume, news and
                                        social volume etc. The news items and
                                        tweets can be viewed by hovering over
                                        the chart and separately in the document
                                        pane. Each news and tweet is color coded
                                        to reflect positive, negative and
                                        neutral sentiment along with the
                                        sentiment score for the document.
                                    </p>
                                    <p>
                                        The{" "}
                                        <b>
                                            Natural Language Processing (NLP)
                                            Query Builder
                                        </b>{" "}
                                        can be used to build and test queries
                                        for both financial and non-financial
                                        events that influence investor and
                                        trader sentiment. The query builder uses
                                        Machine Learning to display a list of
                                        trending authors, persons, keywords,
                                        companies, and categories for the
                                        selected stock and date range. The
                                        analyst is prompted to build a query
                                        using these trending items for the
                                        specific event being researched. The
                                        developed queries can be backtested and
                                        saved.
                                    </p>
                                    <p>
                                        {" "}
                                        This is a first version of SST Research.
                                        Upcoming versions will support a larger
                                        number of stocks, and additional sources
                                        for sentiment analysis. The next version
                                        will also enable investment decisions
                                        based on <b>Search analytics</b> using
                                        Google Trends etc. Do write to us at{" "}
                                        <a href='mailto:contactus@finsoftai.com'>
                                            contactus@finsoftai.com
                                        </a>{" "}
                                        , if you want us to support specific
                                        stocks or need additional custom
                                        features.
                                    </p>
                                    <p>
                                        {" "}
                                        <b>© 2021FinSoftAi</b>-{" "}
                                        <a href='https://www.finsoftai.com/'>
                                            Social Sentiment Insights
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </Modal>
                    </div>

                    <div className='row align-middle' style={{ width: "100%" }}>
                        <a
                            className='navbar-brand col-md-auto'
                            style={{ margin: "auto", padding: 0 }}>
                            <img
                                src={logo}
                                alt=''
                                width={200}
                                style={{
                                    filter: "drop-shadow(0px 3px 3px lightgrey)"
                                }}></img>
                        </a>
                        <div className='col-md-auto' style={{ margin: "auto" }}>
                            <Button
                                className={props.classList[1]}
                                size='small'
                                variant='contained'
                                style={{
                                    padding: "10px 20px",
                                    color: "white",
                                    borderRadius: 30,
                                    fontWeight: 500,
                                    fontSize: 16,
                                    backgroundColor: "#0868d4"
                                }}
                                onClick={() => {
                                    props.newFlag(true)
                                    setQueryText(() => {
                                        return "||"
                                    })
                                    setQueryVersion(() => {
                                        return ""
                                    })
                                    setQueryName(() => {
                                        return ""
                                    })
                                    props.setRunHelp(false)
                                }}>
                                New Query
                            </Button>
                        </div>
                        <div
                            className={
                                "col-md-3 m-0 mt-3 ml-1 mr-3 " +
                                props.classList[0]
                            }
                            style={{ margin: "auto" }}>
                            <FormControl
                                variant='outlined'
                                style={{
                                    width: "100%",
                                    height: "80%",
                                    marginTop: 6,
                                    paddingTop: 6,
                                    backgroundColor: "white",
                                    marginRight: 20
                                }}>
                                <Select
                                    native
                                    variant='outlined'
                                    value={queryText}
                                    onChange={handleChange}
                                    className={classes.select}
                                    label='Query Name'
                                    key={Math.floor(Math.random() * 99999)}
                                    inputProps={{
                                        name: "query",
                                        id: "outlined-query-native-simple",
                                        classes: {
                                            icon: classes.icon
                                        }
                                    }}
                                    classes={{
                                        outlined: classes.outlined,
                                        root: classes.root,
                                        selectMenu: classes.selectMenu
                                    }}
                                    style={{
                                        backgroundColor: "white",
                                        height: "70%",
                                        paddingTop: 0,
                                        position: "relative",
                                        borderRadius: 25
                                    }}>
                                    <option value='' disabled>
                                        Select Existing Queries
                                    </option>
                                    {queryOptions}
                                </Select>
                            </FormControl>
                        </div>
                        <div
                            className='col-md-auto ml-5 pl-5 pr-5'
                            style={{ margin: "auto", height: "50%" }}>
                            <IconButton
                                size='small'
                                variant='outlined'
                                style={{
                                    height: "50%",
                                    width: "100%",
                                    color: "#474747",
                                    borderRadius: 10,
                                    padding: 8,
                                    fontWeight: 600,
                                    fontSize: 18,
                                    backgroundColor: "#f9ce1b",
                                    boxShadow: " 2px 2px lightgrey"
                                }}
                                onClick={() => {
                                    setQueryText((t) => {
                                        return "Announcement to add TSLA to S&P 500||1"
                                    })
                                    setQueryVersion((t) => {
                                        return "1"
                                    })
                                    setQueryName((t) => {
                                        return "Announcement to add TSLA to S&P 500"
                                    })
                                    setTimeout(() => {
                                        props.setRunHelp(true)
                                        props.newFlag(false)
                                        console.log("REUN SET")
                                    }, 50)
                                    // setTour()
                                }}>
                                Take a Tour
                                <DirectionsWalkIcon />
                            </IconButton>
                        </div>
                        <div
                            className='col-md-auto mt-3 ml-2 mb-2 pl-5 pr-5'
                            style={{ display: "inline" }}>
                            <IconButton
                                className='new-step-four'
                                variant='outlined'
                                style={{
                                    height: "90%",
                                    width: "100%",
                                    fontWeight: 800,
                                    fontSize: 30,
                                    borderRadius: 10
                                }}
                                onClick={() => {
                                    setToggle(true)
                                }}>
                                {/* <h4 style={{ marginTop: 8, marginRight: 5 }}> About </h4> */}
                                <HelpOutlineIcon fontSize='large' />
                            </IconButton>
                        </div>
                        <div
                            className='col-md-auto mt-3 ml-5 mb-2 pl-5 pr-2'
                            style={{ display: "inline" }}>
                            <IconButton
                                variant='outlined'
                                className='new-step-one'
                                style={{
                                    height: "90%",
                                    width: "100%",
                                    fontWeight: 800,
                                    fontSize: 30,
                                    borderRadius: 10
                                }}
                                onClick={() => {
                                    setScreenModalToggle(true)
                                }}>
                                <DisplaySettingsOutlinedIcon fontSize='large' />
                            </IconButton>
                        </div>
                        <div
                            className='col-md-auto mt-3 ml-5 mb-2 mr-0 text-right d-flex'
                            style={{ marginLeft: "auto", textAlign: "right" }}>
                            <h5
                                style={{
                                    fontSize: 20,
                                    color: "#7e7f82",
                                    marginTop: 15,
                                    marginRight: 15
                                }}>
                                {accountService.userValue
                                    ? accountService.userValue.firstName +
                                      " " +
                                      accountService.userValue.lastName
                                    : "Research Analyst"}
                            </h5>
                            <div>
                                <IconButton
                                    aria-controls='simple-menu'
                                    aria-haspopup='true'
                                    color='primary'
                                    aria-label='Person'
                                    style={{
                                        height: 50,
                                        width: 50,
                                        fontSize: 20,
                                        marginRight: 15,
                                        backgroundColor: "#f2f2f2"
                                    }}
                                    onClick={handleClick}>
                                    <PersonIcon
                                        style={{
                                            height: 30,
                                            width: 30,
                                            fontSize: 20,
                                            color: "#024ea2"
                                        }}
                                    />
                                </IconButton>
                                <Menu
                                    id='simple-menu'
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={() => {
                                        handleClose(null)
                                    }}>
                                    {/* <MenuItem> <NavLink style={{ color: 'black', textDecoration: 'none', fontWeight: 400 }} exact to="/" onClick={() => { handleClose(null); }}>Home</NavLink></MenuItem>
                  <MenuItem> <NavLink style={{ color: 'black', textDecoration: 'none', fontWeight: 400 }} to="/profile" onClick={() => { handleClose(null); }}>Profile</NavLink></MenuItem> */}
                                    {/* {user.role === Role.Admin &&
                    <MenuItem> <NavLink style={{ color: 'black', textDecoration: 'none', fontWeight: 400 }} to="/admin">Admin</NavLink></MenuItem>
                  } */}
                                    {/* <MenuItem> <a onClick={() => { handleClose(null); }}>Set Screens</a></MenuItem> */}
                                    <MenuItem>
                                        {" "}
                                        <a
                                            onClick={() => {
                                                logout(history)
                                            }}>
                                            Logout
                                        </a>
                                    </MenuItem>
                                </Menu>
                            </div>
                        </div>
                        <div>
                            <Modal
                                aria-labelledby='transition-modal-title'
                                aria-describedby='transition-modal-description'
                                className={classes.modal}
                                open={screenModalToggle}
                                keepMounted
                                onClose={handleScreenModalClose}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500
                                }}>
                                <div
                                    classes='container-fluid'
                                    className={classes.paper}
                                    style={{
                                        maxWidth: "70%",
                                        overflowY: "scroll",
                                        maxHeight: "80%"
                                    }}>
                                    <div className='row'>
                                        <h2
                                            className='col-10 transition-modal-description'
                                            style={{ textAlign: "left" }}>
                                            Configure Screen Layout
                                        </h2>
                                        <div
                                            className='col-2'
                                            style={{ textAlign: "right" }}>
                                            <IconButton
                                                variant='outlined'
                                                onClick={() => {
                                                    handleScreenModalClose()
                                                }}>
                                                <CloseIcon />
                                            </IconButton>
                                        </div>
                                        {/* <div className='col-1'></div> */}
                                    </div>
                                    <hr></hr>
                                    <div
                                        style={{
                                            textAlign: "center",
                                            fontFamily: "Poppins"
                                        }}>
                                        <Typography
                                            variant='h6'
                                            component='div'
                                            style={{ marginBottom: "10px" }}>
                                            Input Parameters
                                        </Typography>
                                        <ToggleButtonGroup
                                            color='primary'
                                            value={parametersToggle}
                                            exclusive
                                            size='small'
                                            onChange={handleParametersChange}
                                            aria-label='Platform'>
                                            <ToggleButton value='general'>
                                                General
                                            </ToggleButton>
                                            <ToggleButton value='esg'>
                                                ESG
                                            </ToggleButton>
                                            <ToggleButton value='both'>
                                                Both
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                    <hr></hr>
                                    <div
                                        style={{
                                            textAlign: "center",
                                            fontFamily: "Poppins",
                                            margin: "20px 0"
                                        }}>
                                        <Typography
                                            variant='h6'
                                            component='div'>
                                            Turn ON/OFF Output Panels
                                        </Typography>
                                    </div>
                                    <div
                                        className='row justify-content-center'
                                        style={{
                                            textAlign: "center",
                                            fontFamily: "Poppins",
                                            fontSize: "1.1rem",
                                            fontWeight: 400,
                                            width: "100%"
                                        }}>
                                        <div className='col-md-5 pb-1'>
                                            <Card variant='outlined'>
                                                <CardMedia
                                                    component='img'
                                                    // height="40"
                                                    image={compositeSentImg}
                                                    alt='green iguana'
                                                />
                                                <CardContent>
                                                    <Typography
                                                        gutterBottom
                                                        variant='h5'
                                                        component='div'>
                                                        Composte Sentiment
                                                    </Typography>
                                                    <Typography
                                                        variant='body2'
                                                        color='text.secondary'></Typography>
                                                    <div>
                                                        <Switch
                                                            checked={
                                                                screensOnDisplay.composite
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                event.persist()
                                                                setScreens(
                                                                    "composite",
                                                                    event
                                                                )
                                                            }}
                                                            inputProps={{
                                                                "aria-label":
                                                                    "controlled"
                                                            }}></Switch>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </div>

                                        <div className='col-md-5 pb-1'>
                                            <Card variant='outlined'>
                                                <CardMedia
                                                    component='img'
                                                    // height="40"
                                                    image={graphsImg}
                                                    alt='green iguana'
                                                />
                                                <CardContent>
                                                    <Typography
                                                        gutterBottom
                                                        variant='h5'
                                                        component='div'>
                                                        Sentiment Charts
                                                    </Typography>
                                                    <Typography
                                                        variant='body2'
                                                        color='text.secondary'>
                                                        Displays Sentiment vs
                                                        Stock price and Social
                                                        buzz vs Trading volume
                                                        over the query period.
                                                        Displays various
                                                        sentiment based
                                                        technical indicators.
                                                    </Typography>
                                                    <div>
                                                        <Switch
                                                            checked={
                                                                screensOnDisplay.graph
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                event.persist()
                                                                setScreens(
                                                                    "graph",
                                                                    event
                                                                )
                                                            }}
                                                            inputProps={{
                                                                "aria-label":
                                                                    "controlled"
                                                            }}></Switch>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </div>
                                        <div className='col-md-5 pt-1'>
                                            <Card variant='outlined'>
                                                <CardMedia
                                                    component='img'
                                                    // height="150"
                                                    style={{}}
                                                    image={pieChartsImg}
                                                    alt='green iguana'
                                                />
                                                <CardContent>
                                                    <Typography
                                                        gutterBottom
                                                        variant='h5'
                                                        component='div'>
                                                        Aggregated Sentiment
                                                    </Typography>
                                                    <Typography
                                                        variant='body2'
                                                        color='text.secondary'>
                                                        Displays the aggregated
                                                        and colour coded view of
                                                        sentiment for each
                                                        source over the query
                                                        period.
                                                    </Typography>
                                                    <div className='pb-2'>
                                                        <Switch
                                                            checked={
                                                                screensOnDisplay.piecharts
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                event.persist()
                                                                setScreens(
                                                                    "piecharts",
                                                                    event
                                                                )
                                                            }}
                                                            inputProps={{
                                                                "aria-label":
                                                                    "controlled"
                                                            }}></Switch>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </div>

                                        <div className='col-md-5 pt-1'>
                                            <Card variant='outlined'>
                                                <CardMedia
                                                    component='img'
                                                    height='180'
                                                    image={tablesImg}
                                                    alt='green iguana'
                                                />
                                                <CardContent>
                                                    <Typography
                                                        gutterBottom
                                                        variant='h5'
                                                        component='div'>
                                                        Document Panel
                                                    </Typography>
                                                    <Typography
                                                        variant='body2'
                                                        color='text.secondary'>
                                                        Displays source wise
                                                        documents returned by
                                                        the query that are
                                                        colour coded by
                                                        sentiment.
                                                    </Typography>
                                                    <div>
                                                        <Switch
                                                            checked={
                                                                screensOnDisplay.tables
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                event.persist()
                                                                setScreens(
                                                                    "tables",
                                                                    event
                                                                )
                                                            }}
                                                            inputProps={{
                                                                "aria-label":
                                                                    "controlled"
                                                            }}></Switch>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </div>
                                        <hr className='mt-2'></hr>
                                        <div
                                            className='row justify-content-end align-items-right mt-2'
                                            style={{
                                                textAlign: "right !important",
                                                width: "100%"
                                            }}>
                                            <Button
                                                color='primary'
                                                variant='contained'
                                                className='col-1'
                                                onClick={confirmScreens}
                                                style={{
                                                    backgroundColor: "#034ea2",
                                                    color: "white",
                                                    marginRight: "2%",
                                                    float: "right !important"
                                                }}>
                                                Save
                                            </Button>
                                            <Button
                                                color='primary'
                                                onClick={handleScreenModalClose}
                                                variant='contained'
                                                className='col-1'
                                                style={{
                                                    backgroundColor: "#034ea2",
                                                    color: "white",
                                                    padding: "0px 5px",
                                                    float: "right !important"
                                                }}>
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                        <TrendingGraphs
                            modalToggle={props.dailyModal}
                            setModalToggle={async (data) => {
                                await props.setDailyModal(data)
                            }}></TrendingGraphs>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar
