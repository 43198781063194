import React, { useEffect, useState } from "react"
import LinearProgress from "@mui/material/LinearProgress"
import { makeStyles, withStyles, ThemeProvider } from "@material-ui/core/styles"
import { borderRight } from "@mui/system"
import Button from "@material-ui/core/Button"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import Modal from "@material-ui/core/Modal"
import TextField from "@mui/material/TextField"
import CloseIcon from "@material-ui/icons/Close"
import Backdrop from "@material-ui/core/Backdrop"
import IconButton from "@material-ui/core/IconButton"
import Fade from "@mui/material/Fade"
import Box from "@mui/material/Box"
import Slider from "@mui/material/Slider"
import newsLogo from "./static/news_logo_transparent.png"
import stLogo from "./static/stocktwits_logo_orange.png"
import tweetLogo from "./static/twitter_logo_disabled.png"
import chatLogo from "./static/chatroom_icon.jpeg"
import redditLogo from "./static/reddit_logo.png"
import gtrendsLogo from "./static/google_logo2.png"
import TPLogo from "./static/third_party_logo.png"
import Chip from "@mui/material/Chip"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import { styled } from "@mui/material/styles"
import Snackbar from "@material-ui/core/Snackbar"
import SnackbarContent from "@material-ui/core/SnackbarContent"

const useStyles = makeStyles((theme) => ({
    determinate: {
        background:
            "linear-gradient(90deg, rgba(181,53,32,1) 0%, rgba(207,78,64,1) 25%, rgba(248,204,71,1) 50%, rgba(95,157,95,1) 75%, rgba(59,136,63,1) 100%) !important"
    },
    bar1Determinate: {
        backgroundColor: "rgba(255, 255, 255, 0.05) !important",
        borderRight: "ridge 8px black"
    },
    modal: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
        width: "65% !important"
        // border:'solid 2px red'
    },
    textfield: {
        height: 10
    }
}))

const marks = [
    {
        value: -100,
        label: "-100"
    },
    {
        value: 100,
        label: "100"
    },
    {
        value: 0,
        label: "0"
    }
]

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040"
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        fontSize: 16,
        width: 70,
        textAlign: "center"
    }
}))

const CompositeSent = (props) => {
    const classes = useStyles()
    // const [fadeOpen, setFadeOpen] = React.useState(false);
    const [modalToggle, setToggle] = useState(false)
    const [alertState, setAlertState] = React.useState({
        open: false,
        vertical: "top",
        horizontal: "center",
        severity: "",
        message: ""
    })

    const { vertical, horizontal, alertOpen } = alertState

    const handleModalOpen = () => {
        setToggle(true)
    }

    const handleModalClose = () => {
        setToggle(false)
        if (Object.values(weights).reduce((a, b) => a + b, 0) > 100) {
            handleAlertOpen({
                vertical: "top",
                horizontal: "center",
                message: "Total percentage should be less than 100",
                contentStyle: {
                    backgroundColor: "#f90504"
                }
            })
        }
    }

    const handleAlertOpen = (newState) => {
        setAlertState({ alertOpen: true, ...newState })
    }

    const handleAlertClose = () => {
        setAlertState({ ...alertState, alertOpen: false })
    }
    const [weights, setWeights] = useState({
        stocktwits: 33,
        news: 33,
        thirdParty: 33,
        gtrends: 0,
        reddit: 0
    })
    const [compSentiment, setSentiment] = useState(0)

    useEffect(() => {
        props.setExtWeights(weights)
        if (props.data && Object.keys(props.data).length) {
            getFinalSentiment()
        }
    }, [props])

    useEffect(() => {}, [compSentiment])

    const handleWeights = (event, source, type) => {
        if (type) {
            event.persist()
        }
        if (source === "stocktwits") {
            setWeights((prevState) => {
                return {
                    ...prevState,
                    stocktwits: parseInt(event.target.value)
                }
            })
        } else if (source === "news") {
            setWeights((prevState) => {
                return { ...prevState, news: parseInt(event.target.value) }
            })
        } else if (source === "thirdParty") {
            setWeights((prevState) => {
                return {
                    ...prevState,
                    thirdParty: parseInt(event.target.value)
                }
            })
        } else if (source === "gtrends") {
            setWeights((prevState) => {
                return { ...prevState, gtrends: parseInt(event.target.value) }
            })
        } else if (source === "reddit") {
            setWeights((prevState) => {
                return { ...prevState, reddit: parseInt(event.target.value) }
            })
        }
    }

    const scale = (number) => {
        return ((number - -1) * (100 - 0)) / (1 - -1) + 0
    }

    const getFinalSentiment = () => {
        let finalGT =
            (weights.gtrends *
                ((props.data.gtrends.Buy - props.data.gtrends.Sell) /
                    (props.data.gtrends.Buy + props.data.gtrends.Sell))) /
                100 || 0
        let finalTP =
            (weights.thirdParty *
                ((props.data.third_party.Bullish -
                    props.data.third_party.Bearish) /
                    (props.data.third_party.Bullish +
                        props.data.third_party.Bearish))) /
                100 || 0
        let finalST = (props.data.stocktwits * weights.stocktwits) / 100 || 0
        let finalNews =
            (props.newsToggle === "title"
                ? props.data.ibm_news.title * weights.news
                : props.data.ibm_news.text * weights.news) / 100 || 0
        let tempcomp = finalGT + finalTP + finalST + finalNews
        setSentiment(() => {
            return tempcomp
        })
    }

    return (
        <div
            style={{ width: "80%", marginTop: 10 }}
            className='row justify-content-center'>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={alertOpen}
                onClose={handleAlertClose}
                // message={alertState.message}
                autoHideDuration={2000}
                key={vertical + horizontal}
                severity='info'
                bodyStyle={{ backgroundColor: "teal", color: "coral" }}>
                <SnackbarContent
                    message={alertState.message}
                    style={alertState.contentStyle}></SnackbarContent>
            </Snackbar>
            <div
                className='row'
                style={{
                    backgroundColor: "#f4f4f4",
                    height: "100px",
                    borderRadius: 10,
                    width: "100%"
                }}>
                <h3
                    className='col-md-1'
                    style={{ marginTop: 30, padding: 0, textAlign: "center" }}>
                    -1
                </h3>
                <BootstrapTooltip title={compSentiment.toFixed(4)}>
                    <LinearProgress
                        className='col-md-7'
                        variant='determinate'
                        value={scale(compSentiment)}
                        style={{ margin: "auto", height: 40, borderRadius: 5 }}
                        classes={{
                            determinate: classes.determinate,
                            bar1Determinate: classes.bar1Determinate
                        }}
                    />
                </BootstrapTooltip>
                <h3
                    className='col-md-1'
                    style={{ marginTop: 30, padding: 0, textAlign: "center" }}>
                    1
                </h3>
                <div className='col-md-3' style={{}}>
                    <Button
                        style={{
                            marginTop: "25px",
                            color: "white",
                            fontWeight: 400,
                            fontSize: 18,
                            backgroundColor: "#034ea2"
                        }}
                        variant='contained'
                        endIcon={<KeyboardArrowDownIcon />}
                        onClick={() => {
                            setToggle(true)
                        }}>
                        Set Weights
                    </Button>
                    <div>
                        <Modal
                            aria-labelledby='transition-modal-title'
                            aria-describedby='transition-modal-description'
                            className={classes.modal}
                            open={modalToggle}
                            keepMounted
                            onClose={handleModalClose}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500
                            }}>
                            <Fade in={modalToggle} timeout={500}>
                                <div
                                    classes='container-fluid'
                                    className={classes.paper}
                                    style={{
                                        maxWidth: "90%",
                                        textAlign: "center !important"
                                    }}>
                                    <div className='row'>
                                        <h2
                                            className='col-10 transition-modal-description'
                                            style={{ textAlign: "left" }}>
                                            Set Weights
                                        </h2>
                                        <div
                                            className='col-2'
                                            style={{ textAlign: "right" }}>
                                            <IconButton
                                                variant='outlined'
                                                onClick={() => {
                                                    handleModalClose()
                                                }}>
                                                <CloseIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                    <hr></hr>

                                    <div
                                        className='row'
                                        style={{
                                            textAlign: "left",
                                            fontFamily: "Poppins",
                                            width: "100%",
                                            display: "inline-block",
                                            fontSize: "1rem",
                                            fontWeight: 400
                                        }}>
                                        {/* news */}
                                        <div
                                            className='row flex items-center'
                                            style={{
                                                width: "100%",
                                                marginBottom: 10
                                            }}>
                                            <div
                                                className='col-1'
                                                style={{
                                                    marginTop: 10,
                                                    marginRight: "2%"
                                                }}>
                                                <img
                                                    src={newsLogo}
                                                    height={50}
                                                    width={80}></img>
                                            </div>
                                            <div
                                                className='col-2'
                                                style={{ textAlign: "center" }}>
                                                <div>News</div>
                                                <Chip
                                                    label={weights.news}
                                                    variant='filled'
                                                    style={{ padding: 12 }}
                                                />
                                            </div>
                                            <div
                                                className='col-6'
                                                style={{ paddingRight: 20 }}>
                                                <Box width={460}>
                                                    <Slider
                                                        value={weights.news}
                                                        marks={marks}
                                                        aria-label='Default'
                                                        max={100}
                                                        min={-100}
                                                        onChange={(event) => {
                                                            handleWeights(
                                                                event,
                                                                "news",
                                                                0
                                                            )
                                                        }}
                                                        valueLabelDisplay='auto'
                                                        style={{
                                                            marginTop: "5%"
                                                        }}
                                                    />
                                                </Box>
                                            </div>
                                            <div
                                                className='col-2'
                                                style={{
                                                    marginLeft: "6%",
                                                    marginTop: 20
                                                }}>
                                                <p>Weight</p>
                                                <TextField
                                                    variant='outlined'
                                                    size='small'
                                                    style={{ width: "5rem" }}
                                                    label={null}
                                                    onChange={(event) => {
                                                        handleWeights(
                                                            event,
                                                            "news",
                                                            1
                                                        )
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: false
                                                    }}></TextField>
                                            </div>
                                        </div>
                                        {/* stocktwits */}
                                        <div
                                            className='row'
                                            style={{
                                                width: "100%",
                                                marginBottom: 5,
                                                marginTop: 5
                                            }}>
                                            <div
                                                className='col-1'
                                                style={{
                                                    marginTop: 10,
                                                    marginRight: "2%"
                                                }}>
                                                <img
                                                    src={stLogo}
                                                    height={60}
                                                    width={80}></img>
                                            </div>
                                            <div
                                                className='col-2'
                                                style={{ textAlign: "center" }}>
                                                <div>Stocktwits</div>
                                                <Chip
                                                    label={weights.stocktwits}
                                                    variant='filled'
                                                    style={{ padding: 12 }}
                                                />
                                            </div>
                                            <div
                                                className='col-6'
                                                style={{ paddingRight: 20 }}>
                                                <Box width={460}>
                                                    <Slider
                                                        value={
                                                            weights.stocktwits
                                                        }
                                                        aria-label='Default'
                                                        marks={marks}
                                                        max={100}
                                                        min={-100}
                                                        valueLabelDisplay='auto'
                                                        onChange={(event) => {
                                                            handleWeights(
                                                                event,
                                                                "stocktwits",
                                                                0
                                                            )
                                                        }}
                                                        style={{
                                                            marginTop: "5%"
                                                        }}
                                                    />
                                                </Box>
                                            </div>
                                            <div
                                                className='col-2'
                                                style={{
                                                    marginLeft: "6%",
                                                    marginTop: 20
                                                }}>
                                                <TextField
                                                    variant='outlined'
                                                    size='small'
                                                    style={{ width: "5rem" }}
                                                    label={null}
                                                    type='number'
                                                    onChange={(event) => {
                                                        handleWeights(
                                                            event,
                                                            "stocktwits",
                                                            1
                                                        )
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: false
                                                    }}></TextField>
                                            </div>
                                        </div>
                                        {/* Third Party */}
                                        <div
                                            className='row'
                                            style={{
                                                width: "100%",
                                                marginBottom: 10,
                                                marginTop: 5
                                            }}>
                                            <div
                                                className='col-1'
                                                style={{
                                                    marginTop: 10,
                                                    marginRight: "2%"
                                                }}>
                                                <img
                                                    src={TPLogo}
                                                    height={50}
                                                    width={60}></img>
                                            </div>
                                            <div
                                                className='col-2'
                                                style={{ textAlign: "center" }}>
                                                <div>Third Party</div>
                                                <Chip
                                                    label={weights.thirdParty}
                                                    variant='filled'
                                                    style={{ padding: 12 }}
                                                />
                                            </div>
                                            <div
                                                className='col-6'
                                                style={{ paddingRight: 20 }}>
                                                <Box width={460}>
                                                    <Slider
                                                        value={
                                                            weights.thirdParty
                                                        }
                                                        aria-label='Default'
                                                        marks={marks}
                                                        max={100}
                                                        min={-100}
                                                        valueLabelDisplay='auto'
                                                        onChange={(event) => {
                                                            handleWeights(
                                                                event,
                                                                "thirdParty",
                                                                0
                                                            )
                                                        }}
                                                        style={{
                                                            marginTop: "5%"
                                                        }}
                                                    />
                                                </Box>
                                            </div>
                                            <div
                                                className='col-2'
                                                style={{
                                                    marginLeft: "6%",
                                                    marginTop: 20
                                                }}>
                                                <TextField
                                                    variant='outlined'
                                                    size='small'
                                                    style={{ width: "5rem" }}
                                                    label={null}
                                                    type='number'
                                                    onChange={(event) => {
                                                        handleWeights(
                                                            event,
                                                            "thirdParty",
                                                            1
                                                        )
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: false
                                                    }}></TextField>
                                            </div>
                                        </div>

                                        {/* Gtrends */}
                                        <div
                                            className='row'
                                            style={{
                                                width: "100%",
                                                marginBottom: 5,
                                                marginTop: 5
                                            }}>
                                            <div
                                                className='col-1'
                                                style={{
                                                    marginTop: 10,
                                                    marginRight: "2%"
                                                }}>
                                                <img
                                                    src={gtrendsLogo}
                                                    height={50}
                                                    width={60}></img>
                                            </div>
                                            <div
                                                className='col-2'
                                                style={{ textAlign: "center" }}>
                                                <div>Gtrends</div>
                                                <Chip
                                                    label={weights.gtrends}
                                                    variant='filled'
                                                    style={{ padding: 12 }}
                                                />
                                            </div>
                                            <div
                                                className='col-6'
                                                style={{ paddingRight: 20 }}>
                                                <Box width={460}>
                                                    <Slider
                                                        disabled
                                                        value={weights.gtrends}
                                                        aria-label='Default'
                                                        marks={marks}
                                                        max={100}
                                                        min={-100}
                                                        valueLabelDisplay='auto'
                                                        onChange={(event) => {
                                                            handleWeights(
                                                                event,
                                                                "gtrends",
                                                                0
                                                            )
                                                        }}
                                                        style={{
                                                            marginTop: "5%"
                                                        }}
                                                    />
                                                </Box>
                                            </div>
                                            <div
                                                className='col-2'
                                                style={{
                                                    marginLeft: "6%",
                                                    marginTop: 20
                                                }}>
                                                <TextField
                                                    disabled
                                                    variant='outlined'
                                                    size='small'
                                                    style={{ width: "5rem" }}
                                                    label={null}
                                                    type='number'
                                                    onChange={(event) => {
                                                        handleWeights(
                                                            event,
                                                            "gtrends",
                                                            1
                                                        )
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: false
                                                    }}></TextField>
                                            </div>
                                        </div>
                                        {/* Reddit */}
                                        <div
                                            className='row'
                                            style={{
                                                width: "100%",
                                                marginBottom: 5,
                                                marginTop: 5
                                            }}>
                                            <div
                                                className='col-1'
                                                style={{
                                                    marginTop: 10,
                                                    marginRight: "2%"
                                                }}>
                                                <img
                                                    src={redditLogo}
                                                    height={50}
                                                    width={60}></img>
                                            </div>
                                            <div
                                                className='col-2'
                                                style={{ textAlign: "center" }}>
                                                <div>Reddit</div>
                                                <Chip
                                                    label={weights.reddit}
                                                    variant='filled'
                                                    style={{ padding: 12 }}
                                                />
                                            </div>
                                            <div
                                                className='col-6'
                                                style={{ paddingRight: 20 }}>
                                                <Box width={460}>
                                                    <Slider
                                                        value={weights.reddit}
                                                        aria-label='Default'
                                                        max={100}
                                                        marks={marks}
                                                        min={-100}
                                                        onChange={(event) => {
                                                            handleWeights(
                                                                event,
                                                                "reddit",
                                                                0
                                                            )
                                                        }}
                                                        valueLabelDisplay='auto'
                                                        style={{
                                                            marginTop: "5%"
                                                        }}
                                                    />
                                                </Box>
                                            </div>
                                            <div
                                                className='col-2'
                                                style={{
                                                    marginLeft: "6%",
                                                    marginTop: 20
                                                }}>
                                                <TextField
                                                    variant='outlined'
                                                    size='small'
                                                    style={{ width: "5rem" }}
                                                    label={null}
                                                    type='number'
                                                    onChange={(event) => {
                                                        handleWeights(
                                                            event,
                                                            "reddit",
                                                            0
                                                        )
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: false
                                                    }}></TextField>
                                            </div>
                                        </div>
                                        {/* Twitter */}
                                        <div
                                            className='row'
                                            style={{
                                                width: "100%",
                                                marginBottom: 5,
                                                marginTop: 5
                                            }}>
                                            <div
                                                className='col-1'
                                                style={{
                                                    marginTop: 10,
                                                    marginRight: "2%"
                                                }}>
                                                <img
                                                    src={tweetLogo}
                                                    height={50}
                                                    width={60}></img>
                                            </div>
                                            <div
                                                className='col-2'
                                                style={{ textAlign: "center" }}>
                                                <div>X- Twitter</div>
                                                <Chip
                                                    label={weights.twitter}
                                                    variant='filled'
                                                    style={{ padding: 12 }}
                                                />
                                            </div>
                                            <div
                                                className='col-6'
                                                style={{ paddingRight: 20 }}>
                                                <Box width={460}>
                                                    <Slider
                                                        disabled
                                                        value={weights.twitter}
                                                        aria-label='Default'
                                                        max={100}
                                                        marks={marks}
                                                        min={-100}
                                                        onChange={(event) => {
                                                            handleWeights(
                                                                event,
                                                                "twitter",
                                                                0
                                                            )
                                                        }}
                                                        valueLabelDisplay='auto'
                                                        style={{
                                                            marginTop: "5%"
                                                        }}
                                                    />
                                                </Box>
                                            </div>
                                            <div
                                                className='col-2'
                                                style={{
                                                    marginLeft: "6%",
                                                    marginTop: 20
                                                }}>
                                                <TextField
                                                    variant='outlined'
                                                    size='small'
                                                    style={{ width: "5rem" }}
                                                    label={null}
                                                    type='number'
                                                    onChange={(event) => {
                                                        handleWeights(
                                                            event,
                                                            "reddit",
                                                            0
                                                        )
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: false
                                                    }}></TextField>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompositeSent
